import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComputer,
  faPhone,
  faLaptop,
} from "@fortawesome/pro-solid-svg-icons";
import BookATechnicianButton from "./BookATechnicianButton";

export const HowToBook = () => {
  return (
    <div
      className={
        "flex flex-col flex-auto  justify-center items-center my-12  w-screen"
      }
    >
      <div className={"flex flex-row py-6 items-center"}>
        <div
          className={"w-[100px]  xs:w-[160px] h-[1px] bg-cover bg-indigo-900"}
        ></div>
        <FontAwesomeIcon
          icon={faLaptop}
          size={"xl"}
          className={"text-j2blue px-4"}
        />
        <div
          className={"w-[100px] xs:w-[160px] h-[1px] bg-cover bg-indigo-900"}
        ></div>
      </div>
      <h1
        className={"text-4xl md:text-6xl font-bold text-j2blue text-center"}
      >
        <span className={"text-j2green"}>&#123;</span> Two Simple Ways To
        Book
      </h1>

      <div
        className={
          "flex flex-col md:flex-row md:justify-around w-1/2 ml-auto mr-auto mt-8 font-bold text-j2blue"
        }
      >
        <div className={"flex-1 text-center py-6  "}>
          <FontAwesomeIcon
            icon={faPhone}
            size={"4x"}
            className={"text-j2blue"}
          />
          <br />
          <div className={"mt-8 text-2xl"}>
            Call Us On
            <br />
            <a href={"tel:61353090469"}>(03) 5309 0439</a>
          </div>
        </div>

        <div className={"flex-1 text-center py-6"}>
          <FontAwesomeIcon
            icon={faComputer}
            size={"4x"}
            className={"text-j2blue"}
          />
          <br />
          <div className={"mt-8 text-2xl"}>
            Book Online
            <br />
            <BookATechnicianButton InvertColor={true} />
          </div>
        </div>
      </div>
    </div>
  );
};
