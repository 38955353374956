import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";

const SimpleIconLink = (props) => {
  return (
    <div className={"group p-8 text-center w-auto"}>
      <Link to={`/our-services/${props.Value}`}>
        <div>
          <FontAwesomeIcon
            icon={props.Icon}
            size={props.IconSize}
            className={
              "text-j2blue  group-hover:underline group-hover:text-j2green"
            }
          />
        </div>
        <div
          className={
            "text-xl mt-2 text-j2blue  group-hover:underline group-hover:text-j2green"
          }
        >
          {props.Text}
        </div>
      </Link>
    </div>
  );
};

SimpleIconLink.defaultProps = {
  Icon: faCoffee,
  IconSize: "4x",
  Text: "",
};

export default SimpleIconLink;
