import star1 from "../img/star1.svg";
import star2 from "../img/star2.png";

export const ReviewItems = (props) => {
  console.log(props);
  return (
    <div className={"h-fit flex flex-col  shadow-xl rounded-2xl p-6 m-4"}>
      <div
        className={
          "flex flex-col  sm:flex-row items-center justify-center sm:justify-between"
        }
      >
        <div className={"flex flex-row my-2"}>
          {props.Rate === 4 ? (
            <>
              <img
                src={star1}
                alt={"star"}
                className={"w-[30px] h-[30px] inline-block"}
              />
              <img
                src={star1}
                alt={"star"}
                className={"w-[30px] h-[30px] inline-block"}
              />
              <img
                src={star1}
                alt={"star"}
                className={"w-[30px] h-[30px] inline-block"}
              />
              <img
                src={star1}
                alt={"star"}
                className={"w-[30px] h-[30px] inline-block"}
              />
              <img
                src={star2}
                alt={"star"}
                className={"w-[28px] h-[28px] inline-block"}
              />
            </>
          ) : (
            <>
              <img
                src={star1}
                alt={"star"}
                className={"w-[30px] h-[30px] inline-block"}
              />
              <img
                src={star1}
                alt={"star"}
                className={"w-[30px] h-[30px] inline-block"}
              />
              <img
                src={star1}
                alt={"star"}
                className={"w-[30px] h-[30px] inline-block"}
              />
              <img
                src={star1}
                alt={"star"}
                className={"w-[30px] h-[30px] inline-block"}
              />
              <img
                src={star1}
                alt={"star"}
                className={"w-[30px] h-[30px] inline-block"}
              />
            </>
          )}
        </div>
        <span className={"text-lg text-black w-1/2 text-center sm:text-right"}>
          {props.Date.substring(8, 10)}/{props.Date.substring(5, 7)}/
          {props.Date.substring(0, 4)}
        </span>
      </div>

      {props.Comment ? (
        <div className={"flex flex-row flex-wrap mt-6 mb-2 text-lg text-black"}>
          {props.Comment}
        </div>
      ) : (
        <div
          className={
            "flex flex-row flex-wrap my-3 text-xl text-gray-500 italic"
          }
        >
          No Comment
        </div>
      )}
    </div>
  );
};
