import BookATechnicianButton from "./BookATechnicianButton";

export const HeroSection = () => {
  return (
    <div
      className={
        "bg-black bg-cover bg-center  flex flex-col " +
        "flex-wrap mt-auto md:py-24 md:pl-24  2xl:py-48  right-0 left-0 mx-0 py-8 w-full md:h-104 items-center md:items-start"
      }
    >
      <div
        className={
          "pl-4 xl:pl-10 text-center align-middle  flex flex-col items-center justify-center"
        }
      >
        <div className={"w-full text-left"}>
          <h1 className={"text-3xl  md:text-6xl font-bold text-white"}>
            <span className={"text-j2green"}>&#123;</span> Your Computer
            Doesn't Boot?
          </h1>
        </div>
        <div className={"w-full mt-6 text-left text-white text-lg"}>
          Simply make a booking for one of our technicians to visit you, and it
          will be good as new in no time!
        </div>
        <div className={"w-full mt-8 text-left"}>
          <BookATechnicianButton />
        </div>
      </div>
    </div>
  );
};
