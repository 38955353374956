import { Link } from "react-router-dom";
import repair from "../img/repair.jpg";
import BookATechnicianButton from "../components/BookATechnicianButton";
import { useEffect } from "react";
import { HowToBook } from "../components/HowToBook";
import SortedServiceAreaList from "../helper/ServicesAreaHelper";
import useDocumentTitle from "../hook/useDocumentTitle";
import {branch} from "../data/config";

const ServiceArea = () => {
  useDocumentTitle(`Service Area | ${branch[0].city} Mobile Computer Help`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className={
        "flex flex-col flex-wrap mx-0 my-0 py-0 px-0 justify-center items-center w-screen"
      }
    >
      {/* START Banner Section */}
      <div
        className={
          "flex flex-col mx-auto left-0 h-auto py-12 pl-20 pr-6 xl:px-48 xl:h-96 bg-slider-1 bg-cover w-full text-white  xl:pt-32 overflow-hidden"
        }
      >
        <div className={"mx-auto mx:pt-12 mx:pt-16 mx:pl-48 w-full"}>
          <div className={"text-3xl  xl:text-6xl font-extrabold"}>
            Service Area
          </div>
          <div className={"flex flex-row text-sm pt-8"}>
            <span>
              <a
                href={"/"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Home
              </a>
            </span>
            <span className={"mx-2 text-xl"}>/</span>
            <span className={" text-xl"}>Service Area</span>
          </div>
        </div>
      </div>
      {/* END Banner Section */}

      {/* START Introduction Section */}
      <div
        className={
          "flex flex-col md:flex-row  mx-auto my-0 w-full px-8  py-8 xl:px-36 xl:pt-20 items-start justify-start  content-center "
        }
      >
        <div className={"order-2 md:order-1 xl:px-6 py-10 w-full xl:w-1/2"}>
          <img src={repair} alt={"repair"} />
        </div>

        <div
          className={
            "order-1 md:order-2 md:pl-12 flex flex-col xl:w-1/2 text-4xl  text-j2blue justify-start"
          }
        >
          <div className={"w-full"}>
            <div>
              <span className={"text-j2green font-extrabold mr-2"}>
                &#123;
              </span>
              <span className={"text-bold font-extrabold"}>
                Our Service Area
              </span>
            </div>

            <div
              className={
                "mt-8 text-gray-600 text-base xl:text-xl  whitespace-pre-wrap pr-10 font-bold"
              }
            >
              Our full service area is below. If your suburb doesn’t appear, we
              may still be able to service you, however additional costs may
              apply, simply contact us to find out!
            </div>

            <div className={"mt-4 mb-4"}>
              <BookATechnicianButton InvertColor={true} />
            </div>
          </div>
        </div>
      </div>
      {/* END Introduction Section */}

      {/* START Services Area List Section */}
      <div className={"w-screen bg-gray-200 h-auto bg-cover md:px-12"}>
        <div
          className={
            "mx-0 py-16 justify-center items-center justify-center text-lg md:text-3xl px-8" +
            " grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 grid-flow-row font-semibold text-j2blue"
          }
        >
          {SortedServiceAreaList.map((area) => {
            return (
              <div
                key={area.name}
                className={
                  "bg-gradient-to-t from-gray-500 to-gray-300  px-4 md:px-8 py-2 rounded-2xl hover:text-gray-200"
                }
              >
                <Link to={`/service-area/${area.Suburb.toLowerCase()}`}>{area.name}</Link>
              </div>
            );
          })}
        </div>
      </div>

      {/* END Services Area List Section */}

      {/* BEGIN How To Book Section */}
      <HowToBook />
      {/* END How To Book Section */}

      {/* START Hero Info Section */}
      <div
        className={
          "bg-black bg-cover bg-center  flex flex-col " +
          "flex-wrap mt-auto md:py-24 md:pl-24  2xl:py-48  right-0 left-0 mx-0 py-8 w-full md:h-full items-center md:items-start"
        }
      >
        <div
          className={
            "pl-4 xl:pl-10 text-center align-middle  flex flex-col items-center justify-center"
          }
        >
          <div className={"w-full text-left"}>
            <h1 className={"text-3xl  md:text-6xl font-bold text-white"}>
              <span className={"text-j2green"}>&#123;</span> Your Computer
              Doesn't Boot?
            </h1>
          </div>
          <div className={"w-full mt-6 text-left text-white text-lg"}>
            Simply make a booking for one of our technicians to visit you, and
            it will be good as new in no time!
          </div>
          <div className={"w-full mt-8 text-left"}>
            <BookATechnicianButton />
          </div>
        </div>
      </div>
      {/* END Hero Info Section */}
    </div>
  );
};

export default ServiceArea;
