import SimpleIconLink from "./SimpleIconLink";

import {
  faComputer,
  faHardDrive,
  faWifi,
  faVirus,
} from "@fortawesome/pro-thin-svg-icons";

const UniqueSellingPropositions = (props) => {
  return (
    <div
      className={
        "md:mb-24 xl:mb-6  mt-48  md:mt-80  w-screen h-auto  pt-4 items-center justify-center content-center md:max-h-80 p-0  left-0 right-0"
      }
    >
      <div
        className={
          "grid grid-cols-2 grid-rows-2 lg:flex lg:flex-row  py-8 px-4 md:px-24 md:py-24 items-center content-center h-auto w-screen place-content-around"
        }
      >
        <SimpleIconLink
          Icon={faComputer}
          IconStyle={"light"}
          Text={"Computer Repairs"}
          Value={"Computer-Repairs"}
        />

        <SimpleIconLink
          Icon={faHardDrive}
          IconStyle={"light"}
          Text={"Data Recovery"}
          Value={"Data-Recovery"}
        />

        <SimpleIconLink
          Icon={faWifi}
          IconStyle={"light"}
          Text={"NBN Help"}
          Value={"NBN-Connections-and-Issues"}
        />

        <SimpleIconLink
          Icon={faVirus}
          IconStyle={"light"}
          Text={"Virus Removal"}
          Value={"Virus-Removal"}
        />
      </div>
    </div>
  );
};

export default UniqueSellingPropositions;
