import { branch } from "./config";
import calendar from "../img/calendar.jpg";
import pad from "../img/computer_pad_mobile.png";
import crush from "../img/crush.jpg";
import blue from "../img/feel_blue.png";
import read from "../img/read_book.jpg";
import event from "../img/events.png";
import police from "../img/police.jpg";
import devices from "../img/web_devices.png";
import office from "../img/office.png";
import virus from "../img/virus.jpg";
import elder from "../img/elder.svg";

export const services = [
  {
    Id: "1",
    Name: `Computer-Repairs`,
    ServiceName: `Computer Repairs`,
    Summary: `Have one of our technicians visit you to resolve your computer issues.`,
    Subtitle: `Computer not working quite right? Is it making funny sounds?`,
    Description: `If your computer is starting to slow down, or you can hear funny sounds, then this is a common sign something is going wrong. Our team can help diagnose and restore your computer back to good health.`,
    QNA: [
      {
        Q: `My Computer Is Slooooowww.....`,
        A: `Slow computers can be caused by a number of things. The most common things in ${branch[0].city} are failing hard disks and viruses. Both of these issues have the potential to destroy data (think photos!) on your computer. If your computer has started slowing down or is making clicking noises, contact ${branch[0].city} Mobile Computer Help immediatly.`,
      },
      {
        Q: `Do You Work On All Types Of Computers?`,
        A: `Yes we do. We can resolve the majority of computer problems. If there is a problem that we believe is better repaired by another company (such as a manufacturer repair under warranty), we’ll let you know.`,
      },
      {
        Q: `Do You Come To Me?`,
        A: `Yes we do. We operate a mobile only service. If a more severe issue does arise, we can take your computer to our computer hospital, then return it back to you once it has been nursed back to good health.`,
      },
      {
        Q: `How Much Will It Cost?`,
        A: `Our technicians will let you know an indicative cost, if the price is expected to exceed the minimum service cost ($${process.env.REACT_APP_PER_HALF_HR_COST}). You can choose whether or not to proceed, before we start the work.`,
      },
      {
        Q: `Weird Errors`,
        A: `Our technicians will let you know an indicative cost, if the price is expected to exceed the minimum service cost ($${process.env.REACT_APP_PER_HALF_HR_COST}). You can choose whether or not to proceed, before we start the work.`,
      },
      {
        Q: `Diagnostics`,
        A: `Our team is kitted out with the latest and greatest in diagnostic tools. We can check if a part is failing, and in some cases predict if a part is on its way out. In both cases, we can put an action plan together to prevent any data loss.`,
      },
      {
        Q: `I Smelt Smoke ...`,
        A: `Look, we’re not going to beat around the bush here … thats never good. . You should really unplug that and give us a call. Oh, and if there is a fire, call the fire brigade first!`,
      },
    ],
    Bottom: `Repairs And Support For 
    Your Windows or Mac Computer`,
    ImgURL: blue,
  },
  {
    Id: "2",
    Name: `Computer-Servicing`,
    ServiceName: `Computer Servicing`,
    Summary: `We can help you pro-actively repair any issues with your computer.`,
    Subtitle: `Computers are a bit like cars! Regular servicing can help to prevent breakdowns... or explosions... or frustration!`,
    Description: `If you rely on your computer, it’s important that you pro-actively repair any issues with your computer, before they become a much bigger issue. Our team can help.`,
    QNA: [
      {
        Q: `What Do You Do?`,
        A: `We can visit you as a one off, or on a schedule to inspect and service any computer you may have. This includes checking and predicting hardware failures (where possible) and ensuring your computer has the latest updates and anti-virus to prevent malicious activity. At the end of each service, we can give you a list of issues that need fixing. Minor issues can be resolved within the service at no extra cost.`,
      },
      {
        Q: `Do You Work On All Types Of Computers?`,
        A: `Yes we do. Mac, Windows or Linux, we work on them all.`,
      },
      {
        Q: `Can You Do This Remotely?`,
        A: `Generally we do our servicing on site. This is to allow the technician to listen to the computer to see if there are any hard disk faults developing and to perform a visual inspection of the computer. In some cases we may do this remotely, but as the price is the same either way, we’d recommend the on-site service.`,
      },
      {
        Q: `Can You Do This Regularly?`,
        A: `Yes! We can schedule you in for a regular service. Twice a year is generally enough for most people, however if you are a business, or heavily dependent on your computer, we’d recommend doing this much more frequently (ie. monthly). If you are dong this more frequently, we can tie this into running updates for you to ensure the computers are running at their best.`,
      },
      {
        Q: `Don't Computers Take Care Of Themselves?`,
        A: `Software vendors will send updates down the line to computers to patch their software. This could be for security or functional reasons. Generally speaking, computers will download and install these updates. What computers don’t do, is ensure that they are fully patched, that their schedules are enabled and that no other faults are developing. We’d recommend servicing your computer regularly to ensure that the automated processes are actually happening.`,
      },
      {
        Q: `My Computer Is "Dusty"`,
        A: `Don’t panic, that is normal to happen. Normal …. but not good. Dust will accumulate in and around your computer, even in the cleanest of rooms. Should dust collect in certain parts of your computer, it will start to slow down and over heat. In extreme cases, it can cause failure of some components. Our technicians can dismantle your computer, clean it out, and put it all back together again.`,
      },
    ],
    Bottom: `Repairs And Support For 
    Your Windows or Mac Computer`,
    ImgURL: blue,
  },
  {
    Id: "3",
    Name: `Computer-Training-and-Assistance`,
    ServiceName: `Computer Training and Assistance`,
    Summary: `We can teach you the topics you’d like to know.`,
    Subtitle: `Looking to learn something new?`,
    Description: `If you’re looking to grow your knowledge in IT, the team at ${branch[0].city} Mobile Computer Help can teach you the topics you’d like to know. From the very basics through to the complex, our technicians can tutor you on what matters to you.`,
    QNA: [
      {
        Q: `Do you come to me?`,
        A: `We come to you and show you on your computer. We find this makes it easier to learn and lets you keep tinkering after our technician has left.`,
      },
      {
        Q: `Do you teach to a specific curriculum?`,
        A: `No we don’t. we tailor each session to your needs. If there is something specific you’d like to learn, we can teach you that, or we can walk through a pre-defined curriculum that another organisation has given you.`,
      },
      {
        Q: `Do I need to know how to use a computer?`,
        A: `Not at all! We can help you get started, right from the very basics.`,
      },
      {
        Q: `How many lessons do I need?`,
        A: `Take the first step and just book one to start with. While our technicians are with you, we can talk about how many lessons and how often you would like. Too many lessons becomes overwhelming – we want you to enjoy the (tech) world that we live in!`,
      },
      {
        Q: `Can I have a standing booking?`,
        A: `Yes, we can schedule regular lessons\\tutorials with you.`,
      },
      {
        Q: `Can you teach programming or web design?`,
        A: `Please contact us with what you would like to learn (including the languages if you know) and we’ll see which of our technicians are experts in the field you want to explore.`,
      },
    ],
    Bottom: `Computer Training 
    In Your Home, At Your Pace`,
    ImgURL: read,
  },
  {
    Id: "4",
    Name: `Backup`,
    ServiceName: `Backup`,
    Summary: `Ensure your important documents and photos are backed up.`,
    Subtitle: `Backups are a bit like seatbelts - you only need them in an accident.`,
    Description: `Backing up your photos and important documents is paramount. If your computer fails, or you get a virus, without backups, your data is gone. Kaput! Our team can advise you the best way to back up your data to stay protected.`,
    QNA: [
      {
        Q: `How Often Should I Backup?`,
        A: ` At least weekly, more often if your data changes regularly.
 Our office backs up every 3 hours – and it happens automatically.`,
      },
      { Q: `I Don't Know How To Back Up`, A: `I Don't Know How To Back Up` },
      {
        Q: `What Are The Odds?`,
        A: `The odds of failure? High. If you don’t have a backup, I’m sure there is some magic mathematical impact that makes it more likely to happen to you. Everyone, at some point loses data. If you have a backup, its generally painless and easy to recover from.`,
      },
      {
        Q: `Can You Check My Backups Are Working?`,
        A: `Yes we can. We can check that your backups are running and also perform test restores to make sure you could get your data back in a crisis.`,
      },
      {
        Q: `Do You Provide Backup Solutions For Business?`,
        A: `We can provide backup support to businesses run from a home, where our staff can visit during our operational hours. We cannot provide support to businesses that operate only outside of our operational hours.`,
      },
      {
        Q: `I Use The Cloud!`,
        A: `Great! Then you are part way there. However, just because you use the cloud, doesn’t mean your data is backed up or protected. In some cases, if the data is maliciously changed on your computer, it will update to the cloud provider and your cloud copy may be corrupted. We’d recommend having one of our technicians take a look to make sure that your backup strategy is suitable for the type of data you are protecting.`,
      },
    ],
    Bottom: `Backup Advice And Support For 
    Your Windows or Mac Computer`,
    ImgURL: pad,
  },
  {
    Id: "5",
    Name: `Data-Recovery`,
    ServiceName: `Data Recovery`,
    Summary: `Hard disk crashed? contact us to find out how to get your data back.`,
    Subtitle: `If your hard disk has crashed with your photos on it - don't panic!`,
    Description: `The team at ${branch[0].city} Mobile Computer Help are specialist data recovery technicians. With access to a specialised clean room, we can recover data from most hard disks and devices.`,
    QNA: [
      {
        Q: `I Think My Hard Disk Or Device Is Corrupt!`,
        A: `First of all, don’t panic – leave that to us. Secondly, it is important that you DO NOT attempt to recover the data yourself. For every recovery attempt that is made, it reduces the success of each subsequent attempts. This means that if you download a tool from the internet or put your hard disk in the freezer, the success rate for recovery may be closer to 0%. Always consult an expert in data recovery if you think something is wrong.`,
      },
      {
        Q: `Is There Anything I Should Do?`,
        A: `As soon as you think data recovery will be required, turn OFF the device that is in question. Computers will continue writing to the hard disk and may hinder recovery. Once you have done this, book an appointment with one of our technicians.`,
      },
      {
        Q: `Can you get all my data back?`,
        A: `We make every attempt to recover all of your data. In most cases we can recover all data, provided that no other data recovery attempts have been made prior to our team taking a look.`,
      },
      {
        Q: `How long does it take?`,
        A: `Data recovery is hard to estimate, however we aim to have all recovery work done in less than 10 business days. For more complex work, we will advise you of time frames and costs. If your recovery is urgent, please let us know and we will expedite your request, noting that additional charges will apply.`,
      },
      {
        Q: `How much does it cost?`,
        A: `Again this is hard to estimate, but as a ballpark, recovery is typically between $425 and $2,000, with most work falling under $1,000. Our technicians can walk you through the process and the associated costs. Where our specialist team needs to asses the hard disk, a $50 fee is charged on top of the technicians visit to you.`,
      },
      {
        Q: `What do you need from me?`,
        A: `Our technicians will attend on-site and will remove your hard disk. Generally speaking, we do not need your whole computer. If your recovery is part of another problem (such as a failed hard disk), we may take your computer, replace the hard disk, return the computer to you and continue the data recovery effort.`,
      },
    ],
    Bottom: `Specialist Data Recovery For 
    Your Windows or Mac Computer`,
    ImgURL: crush,
  },
  {
    Id: "6",
    Name: `Events`,
    ServiceName: `Events`,
    Summary: `We can have one or more technicians at your event, or on call, depending on your needs.`,
    Subtitle: `Running An Event And Need IT Support?`,
    Description: `If you’re running an event that relies on technology, then we’d advise you have IT support on-site to make sure your event runs smoothly. The ${branch[0].city} Mobile Computer Help team can have one or more technicians at your event, or on call, depending on your needs.`,
    QNA: [
      {
        Q: `What Events Can You Help With?`,
        A: `Our team can assist with indoor or outdoor events, from small to large. We can assist by being ready for any issues that arise on the day, through to helping to plan your event from a technology perspective that will help to reduce issues. Public events, weddings, christenings etc are some of the events we can assist you with.`,
      },
      {
        Q: `When Can You Assist?`,
        A: `We can assist with events that occur within our operating hours.`,
      },
      {
        Q: `Do You Loan or Rent IT Equipment?`,
        A: `No, however we can assist you in sourcing the required equipment.`,
      },
      {
        Q: `Where Can You Assist?`,
        A: `Our team can assist your event in and around ${branch[0].city}. For events occurring outside of ${branch[0].city}, we may still be able to assist, but surcharges may apply.`,
      },
      {
        Q: `What Is The Cost?`,
        A: `The charging for events is the same as our normal appointments. That is, $${process.env.REACT_APP_PER_HALF_HR_COST} per 30 minute block. Where multiple technicians are required, these numbers are multiplied by the number of technicians required.`,
      },
      {
        Q: `Do You Attend Event Walkthroughs?`,
        A: `Yes! We’d prefer that you invite us to any walkthroughs or matinees for your event. This ensures we are across what needs to happen and when. We’ll also have a better idea of what could go wrong at different times and have people in place to assist. This however is optional.`,
      },
      {
        Q: `Do You Come To The Event?`,
        A: `That’s up to you! We can have technicians at your event “at the ready” so to speak, or you can call us and we’ll come to your event. The pricing model is the same for both as we reserve technicians time to make sure that we are available for you.`,
      },
      {
        Q: `Do I Need To Provide Food?`,
        A: `If your event is over a meal time, we would appreciate it if you provided a meal, however if you would prefer not to, let us know in advance and we’ll make sure the technician turns up with a packed lunch box. Our team does not take fixed meal brakes during events, and will drop their sandwich if required.`,
      },
    ],
    Bottom: `Installation And Support For 
    Your Event Success`,
    ImgURL: event,
  },
  {
    Id: "7",
    Name: `Internet-Security-and-Firewalls`,
    ServiceName: `Internet Security and Firewalls`,
    Summary: `We ensure that your computer only speaks to other legitimate computers and reduce the likelihood of hacking attempts against you.`,
    Subtitle: `Think Security, Think Firewalls`,
    Description: `A firewall creates a separation between you and the world. Ensure that your computer only speaks to other legitimate computers and reduce the likelihood of hacking attempts against you. 
    
Firewalls can also help protect your family and business from malicious websites, viruses, malware, spyware and ransomware.`,
    QNA: [
      {
        Q: `What are the types of firewalls?`,
        A: `There are two types of firewalls, software and hardware.

A software firewall is a piece of software that runs on your computer. In this scenario, all traffic hits your computer, is ingested and filtered. Some traffic is dropped (the bad stuff) and other traffic is allowed to pass.
         
A hardware firewall is a physical device that sits between your computer and the internet. Many internet providers supply a router that has a firewall built in. In this scenario, traffic is filtered before it gets to your computer and only good traffic is allowed to pass.`,
      },
      {
        Q: `Is my router sufficient?`,
        A: `In many cases, yes it is. However in many cases they are not configured correctly, allowing bad traffic to pass, or preventing good traffic from getting through. The technicians at ${branch[0].city} Mobile Computer Help can look at what you (and your business) use the internet for, to optimise the firewall rules.`,
      },
      {
        Q: `Do I Need A Firewall At Home?`,
        A: `Yes, but generally not to the level a business has. You want to prevent malicious people from attacking your computer, but you probably don’t need to implement website blocking etc.`,
      },
      {
        Q: `I want implement parental controls...`,
        A: `Our team can help you to implement a firewall that prevents access to certain websites, at certain times, from certain computers. This helps to keep your family safe on the internet. We can implement both white listing (only allow certain websites) or blacklisting (disallowing specific websites).`,
      },
      {
        Q: `Can this be done with my existing equipment?`,
        A: `The charging for events is the same as our normal appointments. That is, $${process.env.REACT_APP_PER_HALF_HR_COST} per half hour. Where multiple technicians are required, these numbers are multiplied by the number of technicians required.`,
      },
      {
        Q: `I just want to protect my family/business etc...`,
        A: `No problems at all. Simply book an appointment with our technicians and we’ll walk you through it. No jargon and done for you.`,
      },
      {
        Q: `I've been asked to pay a ransom ...`,
        A: `Contact us immediately to find out what you should do next. Do not pay the ransom without speaking with an expert first.

If you are a home user;please have details about your backups ready and DO NOT plug your backup disks into ANY computer

If you are a business; please have details about your backups ready, do not plug these disks into any computer and have details of your PI insurance ready.`,
      },
    ],
    Bottom: `IT Security For 
    Your Family and Your Business`,
    ImgURL: police,
  },
  {
    Id: "8",
    Name: `NBN-Connections-and-Issues`,
    ServiceName: `NBN Connections and Issues`,
    Summary: `Have NBN problems or not sure where to get started? We can help.`,
    Subtitle: `Got Questions About The NBN?`,
    Description: `The technicians at ${branch[0].city} Mobile Computer Help are all experts in the National Broadband Network, when it comes to getting your home or small business connected. The ${branch[0].city} area is slowly making the transition to NBN and with that comes some considerations for your household, our team can guide you through what you need to know and what you need to do. No geek speak, just easy to understand. If you’re a new construction, or an existing dwelling, from a transition to NBN to a brand new connection, we can help you through the process.`,
    QNA: [
      {
        Q: `What is the National Broadband Network (NBN)?`,
        A: `The NBN is a national network delivering high speed internet to Australians. As the NBN rolls out, the older or legacy technologies are being retired. The NBN delivers phone and internet services through its network. The NBN was designed to cater for Australia’s increased demand in internet services and creates new opportunities in business, education and entertainment.`,
      },
      {
        Q: `Do I Have To Change To NBN?`,
        A: `In short, Yes. The NBN is a replacement for existing telephone networks and internet services. As the NBN rolls out, the older services (in most cases) are switched off. If you don’t change in time, your existing phone and or internet services may be disconnected.`,
      },
      {
        Q: `What Type Of Connection Can I Get?`,
        A: `There are varying types of internet plans available. First of all you need to know what type of connection is available at your address – you can check this by visiting NBN Co. 
        
Typically you’ll see one of the following:

To The Premises (FTTP)– this is where a high speed fibre is run directly to your house. This is the best connection you can obtain on the NBN today.

Fibre To The Building (FTTB)– a high speed fibre is run to the building and then a cable is run from a shared location (such as a basement) to your residence. This is typically found in apartment buildings.

Fibre To The Curb (FTTC)– a high speed fibre is run to the curb and an existing copper cable, such as an existing phone line cable is used to connect to your residence.

Fibre To The Node (FTTN)– a high speed fibre is run to a box out on the street. This box is shared by a number of residences and your existing copper cable, such as an existing phone line is used to bring the internet from the box, to your home.

Fixed Point Wireless– a wireless antenna is fitted to your home, which connects back to a tower to deliver internet services to you.

If this all seems like jargon, that’s OK! At the end of the day, NBN Co will prescribe what type of connection will be available at your place, there is no decision to make on the type of connection. If existing copper is used (as is the case with many connections), this can bring problems over time. The technicians at ${branch[0].city} Mobile Computer Help can let you know what problems may affect you in the future. If you are having internet stability issues on the NBN, then we can advise what may be causing these issues and what to do to resolve them.`,
      },
      {
        Q: `What Internet Provider Should I Go With?`,
        A: `At the end of the day, everyone providing internet services on the NBN is a reseller. That means, that most companies are selling the same thing, at different price points.

Where providers are different is the amount of capacity they purchase on the NBN for their customers to use. Some providers will have a small amount of capacity, assuming that not all of their customers will use the internet at the same time. This may be true in some cases, but in the evening is when most people use the internet, so you would expect the internet to be slower in the evening, with these providers.

Other providers ensure that they have enough capacity for their customers to use, whenever they want to use it. These providers are typically a little more expensive, but you will end up with a more reliable connection.

Your existing internet provider will have NBN plans available. Our team can help you choose which internet providers would be ideal for you and your family.`,
      },
      {
        Q: `Does ${branch[0].city} Mobile Computer Help Have Internet Plans?`,
        A: `No we don’t. ${branch[0].city} Mobile Computer Help can assist you in selecting a plan and helping you get connected, but we do not provide internet plans ourselves. We also do not receive commissions or incentives for the plans that we recommend to you.`,
      },
      {
        Q: `I Have Medical Devices, What Should I Do?`,
        A: `If you have medical devices in your home, then you must advise any internet provider, as well as our technicians. In this case, you require a special power supply for your NBN. The majority of NBN connections, when the power goes out, the NBN in your home goes down with it. NBN connections for medical devices have a battery backup fitted.

We would recommend that you consult with our technicians so that we can ensure that your NBN connection is properly setup for your medical devices.`,
      },
      {
        Q: `I've Gone Over My Data Limit!`,
        A: `Sometimes this happens, and most internet providers are very understanding. For example, Telstra has a “Three for Free” option so you can extend your data usage 3 times a year. If you think you have gone over your limit or you have a massive bill, book an appointment with a technician today. We can often find an option that will work for you and your internet provider.`,
      },
      {
        Q: `What Can ${branch[0].city} Mobile Computer Help Do To Help Me?`,
        A: `Our trained technicians can guide you through buying a NBN plan, through to setting it up for you. We take the jargon out of the process and make it as simple as possible. Once installed and setup, you can always reach out and book an appointment if you have any questions or have any issues with the NBN.

`,
      },
      {
        Q: `I've Heard Bad Things About NBN ...`,
        A: `It’s true the NBN hasn’t been without its issues. At the end of the day, moving to the NBN is going to be forced upon you. If something isn’t quite right, our team can help you get back on track and assist with any complaints and escalation process.`,
      },
    ],
    Bottom: `Installation And Support For
National Broadband Network (NBN)`,
    ImgURL: devices,
  },
  {
    Id: "9",
    Name: `Networking-and-WiFi`,
    ServiceName: `Networking and WiFi`,
    Summary: `Networking allows your devices to communicate and Wi-Fi allows your devices to communicate wirelessly.`,
    Subtitle: `In Your Connected HomeOffice, Everything Needs To Talk`,
    Description: `As our homes and offices become smarter, each device needs a way to talk to each other and to the internet. Networking allows your devices to communicate and Wi-Fi allows your devices to communicate wirelessly.
`,
    QNA: [
      {
        Q: `Do I need a home network?`,
        A: `If you have ADSL or NBN, you will already have a network in your home. A network allows your computers, phones, TV and other smart devices to access the internet.

`,
      },
      {
        Q: `Can you help me setup my Wifi?`,
        A: `Yes we can. Our technicians can configure your wifi for you, as well as suggesting optimal placement for your wireless router.`,
      },
      {
        Q: `My wireless coverage is terrible!
`,
        A: `Once our technicians have visited your premises, we can asses what sort of house you have, the location of your router and where you want to use your internet. Based on this, we can suggest the best way to ensure coverage throughout your home or office.`,
      },
      {
        Q: `Do you install data ports in houses?`,
        A: `No we don’t, however we can co-ordinate electricians as required to do this. Most devices now days are wireless, so data cabling is becoming less and less common.`,
      },
      {
        Q: `My Wifi Is Slow`,
        A: `No problem, our technicians can use our diagnostic tools to determine why your Wifi is slow and what can be done about it.`,
      },
    ],
    Bottom: `Networking and Wifi For 
    Your Connected Home`,
    ImgURL: police,
  },
  {
    Id: "10",
    Name: `New-Computers-and-Upgrades`,
    ServiceName: `New Computers and Upgrades`,
    Summary: `We can help you choose a computer that fits your needs best. `,
    Subtitle: `Need a computer that suits your needs?`,
    Description: `By utilizing our knowledge of the market, the technicians at ${branch[0].city} Mobile Computer Help can help you choose a computer that fits your needs best. Need a desktop? No problems. Need a laptop – even better. Our team is independent to manufacturers and can help you make the best decision for your budget.

We can even custom build desktop computers if you can’t get enough power off the shelf!`,
    QNA: [
      {
        Q: `How do you know what I need?`,
        A: `When our technician visits you, they’ll ask how you use your computer and what kinds of things you use it for. Following this, we’ll work with you to determine a budget. Based on this information we can recommend a computer for you.

`,
      },
      {
        Q: `Do I have to buy the computer from you?`,
        A: `No. In many cases we’ll recommend another store to purchase your computer from. If you have special needs for your computer, we can source it for you, but generally we will help you locate the best option, in the local area.`,
      },
      {
        Q: `Can you help me setup my new computer?`,
        A: `Yes we can. Our technicians can come to your home or office to setup the computer and configure it as you require.`,
      },
      {
        Q: `Can you transfer data from my old computer?`,
        A: `Yes, we can transfer data from your old computer, if it is operation. Note that software\\applications are different and may have their own requirements when being transferred (such as licensing).

If your old computer is not operational, we may still be able to transfer the data, we would just need to do a data recovery first.`,
      },
      { Q: `Do you custom build computers?`, A: `Yes we do.` },
    ],
    Bottom: `Power and Reliability In
Your New Computer`,
    ImgURL: pad,
  },
  {
    Id: "11",
    Name: `Scheduled-Maintenance`,
    ServiceName: `Scheduled Maintenance`,
    Summary: `We can schedule updates to occur out of hours and even test your applications once the updates are installed. `,
    Subtitle: `Simplify your computer and server updates`,
    Description: `The team at ${branch[0].city} Mobile Computer Help are specialists when it comes to installing updates to your computer or server. We can schedule updates to occur out of hours and even test your applications once the updates are installed. Minimise the impact to you and your business and have someone else worry about security patching.`,
    QNA: [
      {
        Q: `When do you run updates?`,
        A: `We typically run maintenance and updates on the first Saturday of each month. However, if this doesn’t fit your schedule, we can accomodate maintenance and patching in any of our operating hours.

`,
      },
      {
        Q: `Do you do this remotely or on-site?`,
        A: `We can do the maintenance and patching remotely or on-site, whichever you prefer. Most of our customers choose to have this done remotely.`,
      },
      {
        Q: `What is included in maintenance?`,
        A: `Our team will check hard disk usage, fragmentation, pending updates, known security issues, drivers, error logs and much more. Think of it like a 100 point safety check for your car.

`,
      },
      {
        Q: `What if you find issues?`,
        A: `If issues arise, for the items we can fix as part of maintenance we will do so. For bigger issues or forecasted issues, we will let you know so that you can choose what you’d like to do.`,
      },
      {
        Q: `Do you tell me what updates were done?`,
        A: `Yes we do. You receive a full list of updates that are applied, after the patching is completed.`,
      },
      {
        Q: `Do you make sure my computer still works?`,
        A: `Yes we do. Our team can check critical software to ensure that it is still functioning as expected. At a basic level, we ensure that the computer appears to be ok, however we can perform more thorough testing as required.

`,
      },
      {
        Q: `I run an IT company, can you patch my customers computers over the weekend?`,
        A: `Yes we can – please contact us for a quote.

`,
      },
    ],
    Bottom: `Scheduled Maintenance
Done For You`,
    ImgURL: calendar,
  },
  {
    Id: "12",
    Name: `Small-Business-Support`,
    ServiceName: `Small Business Support`,
    Summary: `We support businesses after hours and over the weekend. We can fix most IT related problems in your home office.`,
    Subtitle: `Does your business need IT Support?`,
    Description: `If you are running a business that depends on technology, and lets face it, most businesses are – then its important to have a great IT company on call, when you need them. The team at ${branch[0].city} Mobile Computer Help support businesses after hours and over the weekend. We can fix most IT related problems in your home office.

Please Note our team can only support you if you are operating from a home office and if you are comfortable with evening and weekend appointments. If you are operating from a business premises, or require appointments during the day, please contact an alternative local IT provider.

`,
    QNA: [
      {
        Q: `Do your provide support during the day?`,
        A: `No – we only provide IT support in our operating hours, which are evenings and weekends. If you require IT support during the day, please reach out to the many other IT companies in ${branch[0].city}.`,
      },
      {
        Q: `I have an urgent issue`,
        A: `No problem, simply use our online booking to book the next available time slot. If you can’t find one suitable, please give us a call and we will see what we can do.`,
      },
    ],
    Bottom: `Small Business Support
In Your Home Office`,
    ImgURL: office,
  },
  {
    Id: "13",
    Name: `Virus-Removal`,
    ServiceName: `Virus Removal`,
    Summary: `We can assist you with whatever is heading your way. No matter what is happening, our team can tell you the best course of action to protect your computer.`,
    Subtitle: `Computer got worms?`,
    Description: `Unfortunately viruses, malware, ransomware and spyware happen. If you think you have a virus, or you have a flashing screen telling you that you do, rest assured that the technicians at ${branch[0].city} Mobile Computer Help can assist you with whatever is heading your way. No matter what is happening, our team can tell you the best course of action to protect your computer.`,
    QNA: [
      {
        Q: `My computer is asking me to pay money. Should I?`,
        A: `Generally speaking, no. It’s advisable to have an expert have a look before you part with any of your hard earned cash. In some cases, it’s a scam, in others its legitimate and others fall somewhere in-between.`,
      },
      {
        Q: `What should I do first?`,
        A: `Firstly, turn the computer off and disconnect it from power, then stick a big post it note on it saying “Don’t Touch”. Afterwards, book an appointment with one of our technicians to remove the virus for you.`,
      },
      {
        Q: `Is my data gone?`,
        A: `In most cases, no. However to establish this we need to investigate first. Simply book an appointment and we will check it out.`,
      },
      {
        Q: `I have lots of computers saying they have a virus`,
        A: `Turn all the computers off and call us to make an appointment.

`,
      },
      {
        Q: `Can't I just restore from backup?`,
        A: `Restoring from backup is a step our technicians will take with you. If this is done too early, the data on your backup drive may be corrupted by the virus as well. Do not connect your backup drive to your computer while you think a virus is present.`,
      },
      {
        Q: `My files are all there, but I can't access them`,
        A: `It’s likely you have an encrypting virus like crypto locker that locks up all your files until you pay them a ransom. Contact our team before you pay any ransom money.

`,
      },
    ],
    Bottom: `Virus Removal
In Your Home`,
    ImgURL: virus,
  },
  {
    Id: "14",
    Name: `Seniors-Eldertech`,
    ServiceName: `Senior & Elder Technology`,
    Summary: `Our specialised team works with families to keep them connected & digitally safe, no matter where they are.`,
    Subtitle: `Stay Connected, Stay Safe Online - with 50% off our standard rate for eligible seniors`,
    Description: `At ${branch[0].city} Mobile Computer Help, we genuinely care for our elderly customers and take pride in providing a personal touch to our services. We not only enjoy solving their technology problems but also value the time spent with them over a cup of tea, fostering meaningful connections.
    
Our approach is centered on patience, empathy, and understanding, ensuring that our elderly clients feel comfortable and supported as they navigate the digital world. By combining expert technical assistance with warm, friendly interactions, we aim to make technology accessible and enjoyable for all.`,
    QNA: [
      {
        Q: `How Many Years Experience Does Your Team Have?`,
        A: `Our team has collective 40+ years of experience, working with all age demographics.`,
      },
      {
        Q: `Can I organise an appointment for my family member?`,
        A: `Absolutely and this is quite common. Let us know when you are booking at we will let you know when we will be there, as well as letting you know how the appointment went.`,
      },
      {
        Q: `What problems can you help with?`,
        A: `We can assist with downloading, installing, and exploring various apps, setting up an email account to stay connected with family and friends, and implementing multi-factor authentication for enhanced online security. Additionally, we offer support in configuring your new smart TV, accessing and using streaming apps, setting up apps (such as Uber), and providing guidance on online safety issues and training. And that's just the beginning! If there is something that we can't help with, we will try to find the right person for you.`,
      },
      {
        Q: `Can you visit aged care facilities?`,
        A: `Yes we can.`,
      },
      {
        Q: `Can my home care package or NDIS package fund appointments?`,
        A: `In many cases yes, however we need to work with your plan manager to confirm your eligibility.`,
      },
      {
        Q: `Is there a seniors discount?`,
        A: `Yes, provided you can present a seniors card, or the appointment takes place in an aged care facility, we will apply a discount to your invoice.`,
      },
      {
        Q: `Do you sell computers, phones etc.?`,
        A: `No we don't, however we can co-ordinate pickup and delivery of computer, phones and other items from local retailers.`,
      },
      {
        Q: `Can I have the same technician each time?`,
        A: `Wherever we can, we will slot you in with your normal or preferred technician.`,
      },
      {
        Q: `I live interstate but need someone to help with a grandparent - can you help?`,
        A: `Yes and this is fairly common. Let us know your circumstances and we will work with you.`,
      },
    ],
    Bottom: `Technology support for seniors & elderly. We come to you.`,
    ImgURL: elder,
    PercentReduction: 50
  },
];
