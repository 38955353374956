import { useEffect, useState } from "react";
import { branch } from "../data/config";
import { HowToBook } from "../components/HowToBook";
import { ReviewItems } from "../components/ReviewItems";
import { API } from "../data/API";
import Pagination from "../components/Pagination";
import { HeroSection } from "../components/HeroSection";
import useDocumentTitle from "../hook/useDocumentTitle";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const Testimonials = () => {
  useDocumentTitle(`Testimonials | ${branch[0].city} Mobile Computer Help`);

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(18);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchReviews = async () => {
      try {
        const response = await API.get(
          `${BASE_URL}/Feedback?Branch=${branch[0].city}`
        );
        console.log("response:", response.data);
        const newData = response.data.sort(function (a, b) {
          if (a.rating === b.rating) {
            return (
              new Date(b.date.substring(0, 10).replace(/-/g, "/")).getTime() -
              new Date(a.date.substring(0, 10).replace(/-/g, "/")).getTime()
            );
          }
          return b.rating - a.rating;
        });
        setResult(newData);
        setLoading(false);
        console.log("result:", result);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    };
    fetchReviews();
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = result.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(result.length / recordsPerPage);

  return (
    <div
      className={
        "flex flex-col flex-wrap mx-0 my-0 py-0 px-0 justify-center items-center w-screen"
      }
    >
      {/* START Banner Section */}
      <div
        className={
          "flex flex-col mx-auto left-0 h-auto py-12 pl-20 pr-6 xl:px-48 xl:h-96 bg-slider-1 bg-cover w-full text-white  xl:pt-32 overflow-hidden"
        }
      >
        <div className={"mx-auto mx:pt-12 mx:pt-16 mx:pl-48 w-full"}>
          <div className={"text-3xl  xl:text-6xl font-extrabold"}>
            Testimonials
          </div>
          <div className={"flex flex-row text-sm pt-8"}>
            <span>
              <a
                href={"/"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Home
              </a>
            </span>
            <span className={"mx-2 text-xl"}>/</span>
            <span className={" text-xl"}>Testimonials</span>
          </div>
        </div>
      </div>
      {/* END Banner Section */}
      <div className={"text-center sm:text-left flex flex-col "}>
        <h1
          className={
            "text-bold font-extrabold text-j2blue text-6xl mt-6 py-6  "
          }
        >
          <span className={"text-j2green font-extrabold mr-2"}>&#123;</span>
          Client's Reviews
        </h1>
        <span
          className={
            "text-center sm:text-left text-lg text-gray-700 px-4 sm:px-0 "
          }
        >
          See what clients think of their experience with {branch[0].city}{" "}
          Mobile Computer Help
        </span>
      </div>
      {/* START Reviews Section */}
      {loading ? (
        <div
          className={
            "flex flex-col flex-wrap mx-0 my-0 py-20 px-0 justify-center items-center w-screen h-2/3"
          }
        >
          <h1 className={"text-gray-500 text-7xl font-bold"}>Loading ... </h1>
        </div>
      ) : (
        <div
          className={
            "grid grid-cols-1 md:grid-cols-2 gap-4 items-center justify-center w-screen px-8"
          }
        >
          {currentRecords.map((item) => {
            return (
              <ReviewItems
                key={item.date}
                Rate={item.rating}
                Date={item.date}
                Comment={item.comment}
              />
            );
          })}
        </div>
      )}

      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {/* End Reviews Section */}
      <HowToBook />
      {/* START Hero Info Section */}
      <HeroSection />
      {/* END Hero Info Section */}
    </div>
  );
};

export default Testimonials;
