import blue from "../../img/feel_blue.png";
import { useEffect } from "react";
import useDocumentTitle from "../../hook/useDocumentTitle";
import {branch} from "../../data/config";

const NotFoundPage = () => {
  useDocumentTitle(`Not Found | ${branch[0].city} Mobile Computer Help`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className={
        "grid grid-cols-1 md:gap-2 md:grid-cols-2  mx-auto my-0 w-full h-auto mb-6 px:4 md:px-36 pt-2 md:pt-0 justify-center items-center content-center "
      }
    >
      <div className={"px-0"}>
        <img
          src={blue}
          alt={"not found page"}
          className={"w-screen md:w-full text-center"}
        />
      </div>

      <div
        className={
          "flex flex-col justify-center text-center items-center whitespace-pre-wrap"
        }
      >
        <div className={"w-full"}>
          <h1 className={"text-gray-400 font-extrabold text-4xl md:text-9xl"}>
            4 0 4
          </h1>
        </div>

        <div
          className={
            "mt-2 md:mt-8 text-j2blue text-3xl md:text-6xl font-extrabold  whitespace-pre-wrap"
          }
        >
          Oops...
        </div>

        <div
          className={
            "mt-2 md:mt-8 text-j2blue text-lg text-bold flex flex-row text-center whitespace-pre-wrap"
          }
        >
          <p>WE'RE SORRY, BUT SOMETHING WENT WRONG.</p>
        </div>
        <div>
          <a
            type={"button"}
            href={"/"}
            className={
              "focus:ring-4 focus:outline-none focus:ring-blue-300 mt-4 max-w-fit font-bold rounded-full text-base text-j2blue px-8 py-2.5 text-center bg-j2green mr-3 md:mr-0 hover:bg-white hover:border-2 border-j2bue justify-center text-center"
            }
          >
            HOMEPAGE
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
