import Logo from "./Logo";
import BookATechnicianButton from "./BookATechnicianButton";
import {openHours} from "../data/openHours";
import CheckMark from "./CheckMark";
import {branch} from "../data/config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/pro-thin-svg-icons";
import {faPhone, faLocationDot} from "@fortawesome/pro-light-svg-icons";
import {services} from "../data/services";
import {Link} from "react-router-dom";
import ChatAppComponent from "./ChatAppComponent";
import j2logo from "../img/j2logo.png"

const Footer = () => {
    return (
        <div
            className={
                "bg-j2blue flex flex-col flex-auto  text-white w-screen h-auto " +
                "md:flex-row md:content-start p-12 items-start md:py-12 md:justify-evenly"
            }
        >
            <div
                className={
                    "justify-center flex-col flex-wrap md:justify-start md:flex md:w-1/4"
                }
            >
                <Logo MaxWidth={"220px"}/>
                <div className={"text-gray-400 font-bold flex flex-col flex-wrap mt-6"}>
                    <p className={"break-words"}>
                        <b>{branch[0].city} Mobile Computer Help</b> is a leading onsite IT
                        Support company for all home and business computer repairs and
                        computer services. We offer a wide range of mobile computer services
                        and mobile computer repairs, all of which can be tailored to suit
                        your particular needs.
                    </p>
                    <br/>
                    <p>We offer appointments between the following hours (only):</p>
                    <br/>
                    <ul className={"text-white"}>
                        {openHours.map((item, i) => {
                            return (
                                <li key={item.Day} className={"font-bold"}>
                                    <CheckMark Color={"text-white"}/> {item.Day} {item.StartTime}{" "}
                                    to {item.EndTime}
                                </li>
                            );
                        })}
                    </ul>
                    If you require an appointment outside these hours, please
                    contact an alternative IT provider in {branch[0].city}.
                </div>
            </div>

            <div className={"mt-6 md:mt-0 md:w-1/5 md:pl-10"}>
                <h1 className={"font-bold text-xl"}>Services</h1>
                <div className={"flex flex-col mt-3 text-gray-400 font-bold text-sm"}>
                    <ul>
                        {services.map((service) => {
                            return (
                                <li
                                    key={service.Id}
                                    className="hover:text-j2green hover:underline"
                                >
                                    <Link to={`/our-services/${service.Name.toLowerCase()}`}>
                                        {service.ServiceName.toUpperCase()}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <div className={"mt-6 md:mt-0 md:w-1/6 md:pl-8"}>
                <h1 className={"font-bold text-xl"}>Quick Links</h1>
                <div className={"flex flex-col mt-3 text-gray-400 font-bold"}>
                  <span className={"hover:text-j2green hover:underline"}>
                    <a href={"/about"}>About</a>
                  </span>
                  <span className={"hover:text-j2green hover:underline"}>
                    <a href={"/contact"}>Contact</a>
                  </span>
                  <span className={"hover:text-j2green hover:underline"}>
                    <a href={"/booking-terms-and-conditions"}>Terms and Conditions</a>
                  </span>
                </div>
                <div className={"mt-6"}>{branch[0].city} Mobile Computer Help is proudly a Journey2 Digital brand.</div>
                <div className={"mt-4"}><a href={"https://journey2.digital"}><img className={"w-64 h-auto"} src={j2logo} alt={"Journey2 Digital Logo"} /></a></div>
            </div>

            <div className={"mt-4 md:mt-0  md:w-1/5 flex flex-col flex-wrap"}>
                <h1 className={"font-bold text-xl"}>Connect With Us</h1>
                <div className={"mt-3 text-gray-400 font-bold"}>
                    <div
                        className={
                            "flex flex-row break-all items-center content-center self-center"
                        }
                    >
                        <FontAwesomeIcon
                            icon={faLocationDot}
                            className={"text-blue-400 mr-4"}
                        />
                        {branch[0].address}
                    </div>
                    <div
                        className={
                            "flex flex-row break-all items-center content-center self-center"
                        }
                    >
                        <FontAwesomeIcon icon={faPhone} className={"text-blue-400 mr-4"}/>
                        <a href={"tel:" + branch[0].phoneNumber}>
                            {branch[0].phoneNumber}
                        </a>
                    </div>
                    <div
                        className={
                            "flex flex-row break-all items-center content-center self-center"
                        }
                    >
                        <a href={"mailto:" + branch[0].emailAddress.toLowerCase()}>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className={"text-blue-400 pr-4"}
                            />
                            {branch[0].emailAddress.toLowerCase()}
                        </a>
                    </div>
                    <div className={"mt-6"}>
                        <BookATechnicianButton/>
                    </div>
                </div>
            </div>
            <ChatAppComponent/>
        </div>
    );
};

export default Footer;
