import HeroSlider from "../components/HeroSlider";
import UniqueSellingPropositions from "../components/UniqueSellingPropositions";
import CheckMark from "../components/CheckMark";
import BookATechnicianButton from "../components/BookATechnicianButton";
import HomepageService from "../components/HomepageService";
import imgComputer from "../img/computer.png";
import imgComputerTop from "../img/computer_top.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faqs } from "../data/faqs";
import { branch } from "../data/config";
import { faBlockQuestion } from "@fortawesome/pro-solid-svg-icons";
import { services } from "../data/services";
import { useEffect } from "react";
import { HowToBook } from "../components/HowToBook";
import { Pricing } from "../components/Pricing";
import { HeroSection } from "../components/HeroSection";
import {openHours} from "../data/openHours";
import useDocumentTitle from "../hook/useDocumentTitle";

const HomePage = () => {
  useDocumentTitle(`${branch[0].city} Mobile Computer Help`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className={
        "flex flex-col flex-auto flex-wrap mt-auto right-0  left-0 mx-0 h-auto w-full "
      }
    >
      <HeroSlider />

      <UniqueSellingPropositions />

      {/* START About Us Section */}
      <div
        className={
          "flex flex-col flex-wrap xl:flex-initial  mt-2 h-auto pb-8 w-screen items-center py-4 2xl:py-16 " +
          " xl:bg-computer-keyboard xl:bg-contain 2xl:bg-cover 2xl:bg-left-top xl:flex-row xl:items-end xl: justify-end "
        }
      >
        <div className={"w-0 xl:w-2/5 2xl:w-2/2 2xl:mr-12"}>{""}</div>
        <div
          className={
            "flex flex-col flex-auto  flex-wrap mt-auto  mx-0 h-auto px-12 " +
            "w-full xl:w-3/5  items-center xl:items-center xl:pl-36 xl:pr-16  xl:text-left  2xl:items-start  " +
            "2xl:w-1/3  2xl:pl-96"
          }
        >
          <div
            className={
              "text-4xl md:text-5xl font-bold text-j2blue self-start"
            }
          >
            <span className={"text-j2green xl:text-left"}>&#123;</span> About
            Us
          </div>
          <div
            className={
              " font-normal flex items- flex-col flex-initial self-start xl:px-0  mt-4 text-gray-900"
            }
          >
            <div className={"flex flex-col flex-wrap flex-grow-0 2xl:pr-32"}>
              {branch[0].city} Mobile Computer Help is a leading onsite IT
              Support company for all home and business computer repairs and
              computer services. We offer a wide range of mobile computer
              services and mobile computer repairs, all of which can be tailored
              to suit your particular needs.
            </div>
            <br />
            <p>
              <b>Here is what you can expect from us:</b>
            </p>
          </div>
          <ul
            className={
              "flex flex-col flex-wrap  md:ml-0 mt-2 font-semibold text-gray-600  text-left self-start"
            }
          >
            <li>
              <CheckMark /> NO CALL OUT FEES
            </li>
            <li>
              <CheckMark /> SIMPLE LOW RATE
            </li>
            <li>
              <CheckMark /> SIMPLE BOOKING PROCESS, BY PHONE OR ONLINE
            </li>
            <li>
              <CheckMark /> SENSATIONAL CUSTOMER SERVICE 7 DAYS A WEEK
            </li>
            <li>
              <CheckMark /> AVAILABLE EXCLUSIVELY AFTER HOURS AND ALL WEEKEND
            </li>
            <li>
              <CheckMark /> IT EXPERTISE DIRECTLY TO YOUR HOME
            </li>
            <li>
              <CheckMark /> HIGHLY SKILLED COMPUTER REPAIR TECHNICIANS THAT
              SPEAK YOUR LANGUAGE
            </li>
            <li>
              <CheckMark /> AN OPTION OF DEALING WITH THE SAME TECHNICIAN EVERY
              TIME (WHERE POSSIBLE)
            </li>
          </ul>
          <div className={"mt-4 self-start"}>
            We have appointments available;
          </div>
          <ul className={"mt-2 font-semibold text-gray-600 self-start"}> 
            {openHours.map((item, i) => {
              return (
                  <li key={item.Day} className={"font-bold"}>
                     <CheckMark /> {item.Day} {item.StartTime}{" "}
                      to {item.EndTime}
                  </li>
              );
             })}
          </ul>
          <div className={"mt-6 self-start"}>
            <BookATechnicianButton InvertColor={true} />
          </div>
          <div
            className={
              "mt-6 text-sm self-start md:text-base pb-5 mb-4 md:mb-0 mb:pb-0 "
            }
          >
            {branch[0].city} Mobile Computer help is exclusively an after hours
            service.
            <br />
            Therefore, we cannot offer appointments during the day, throughout
            the week.
          </div>
        </div>
      </div>
      {/* END About Us Section */}

      {/* START Hero Info Section */}
      <div
        className={
          "bg-people bg-cover bg-center  flex flex-col " +
          "flex-wrap mt-auto md:py-24 md:pl-24  2xl:py-48  right-0 left-0 mx-0 py-8 w-full md:h-full items-center md:items-start"
        }
      >
        <div
          className={
            "pl-4 xl:pl-10 text-center align-middle  flex flex-col items-center justify-center"
          }
        >
          <div className={"w-full text-left"}>
            <h1 className={"text-3xl  md:text-6xl font-bold text-white"}>
              <span className={"text-j2green"}>&#123;</span> Repair And
              Support For
            </h1>
          </div>
          <div
            className={
              "text-3xl font-bold md:text-5xl md:font-thin mt-4 text-white w-full text-left"
            }
          >
            Windows, Linux and Apple Computers.
          </div>
          <div
            className={
              "w-full flex flex-wrap mt-3 text-left text-white text-lg  md:text-3xl"
            }
          >
            We come to you for all your mobile computer help needs,
            <br />
            in and around the Greater {branch[0].city} Region.
          </div>
          <div className={"w-full mt-8 text-left"}>
            <BookATechnicianButton />
          </div>
        </div>
      </div>
      {/* END Hero Info Section */}

      {/* START Services Section */}
      <div
        className={
          "flex flex-col  flex-wrap  py-12 bg-gray-200  w-screen xl:items-center"
        }
      >
        <div
          className={
            " text-4xl md:text-6xl flex flex-wrap justify-center  items-start xl:items-center"
          }
        >
          <h1 className={"font-bold text-j2blue text-center"}>
            <span className={"text-j2green"}>&#123;</span> What Can We Help
            You With
          </h1>
        </div>

        <div className={"flex flex-col px-10 mx:0 xl:flex-row "}>
          <div
            className={
              "flex flex-col order-1 xl:items-end xl: justify-start xl:w-1/3  xl:text-right"
            }
          >
            {services.slice(0, 4).map((item) => {
              return (
                <HomepageService
                  key={item.Id}
                  URL={`/our-services/${item.Name.toLowerCase()}`}
                  ServiceName={item.ServiceName}
                  Summary={item.Summary}
                  InvertArrow={false}
                />
              );
            })}
          </div>

          <div className={"order-last xl:order-2 mt-8 xl:w-1/3"}>
            <img src={imgComputer} className={"mx-auto"} alt={"computer"} />
          </div>

          <div
            className={
              "flex flex-col items-start xl: justify-start order-2 xl:text-left xl:w-1/3"
            }
          >
            {services.slice(4, 7).map((item) => {
              return (
                <HomepageService
                  key={item.Id}
                  URL={`/our-services/${item.Name.toLowerCase()}`}
                  ServiceName={item.ServiceName}
                  Summary={item.Summary}
                  InvertArrow={true}
                />
              );
            })}
            <HomepageService
              key={"more"}
              URL={"/our-services"}
              ServiceName={"More Services"}
              Summary={"Explore our Services page to find more..."}
              InvertArrow={true}
            />
          </div>
        </div>
      </div>
      {/* END Services Section */}

      {/* START Pricing Section */}
      <Pricing />
      {/* END Pricing Section */}

      {/* BEGIN How To Book Section */}
      <HowToBook />
      {/* END How To Book Section */}

      {/* START FAQ Section*/}
      <div className={"bg-gray-100 py-12 mb-0 mb:pt-24 md:pb-24 w-screen"}>
        <div className={"flex flex-col w-2/3 ml-auto mr-auto"}>
          <div className={"flex-1"}>
            <h1
              className={
                "text-4xl md:text-6xl font-bold text-j2blue text-left mb-6"
              }
            >
              <span className={"text-j2green"}>&#123;</span> FAQs
            </h1>

            <div className="accordion rounded-none" id="faqAccordion">
              {faqs.map((item, i) => {
                const collapseTagName = "faq-collapse-" + i;
                const headingTagName = "faq-heading-" + i;
                const isOpen = i === 0;

                return (
                  <div
                    className="accordion-item bg-white border border-gray-200 m-4"
                    key={item.Summary}
                  >
                    <h2 className="accordion-header mb-0" id={headingTagName}>
                      <button
                        className={`
                                                    ${
                                                      isOpen ? "collapsed" : ""
                                                    },
                                                    accordion-button
                                                    relative
                                                    flex
                                                    items-center
                                                    w-full
                                                    py-4
                                                    px-5
                                                    text-base text-j2blue text-left
                                                    bg-white
                                                    border-0
                                                    rounded-none
                                                    transition
                                                    focus:outline-none
                                                    font-bold
                                                    `}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#" + collapseTagName}
                        aria-expanded={isOpen}
                        aria-controls={collapseTagName}
                      >
                        <FontAwesomeIcon
                          icon={faBlockQuestion}
                          className={"text-amber-400 mr-2"}
                        />
                        {item.Summary}
                      </button>
                    </h2>
                    <div
                      id={collapseTagName}
                      className={`accordion-collapse collapse ${
                        isOpen ? "show" : ""
                      }`}
                      aria-labelledby={headingTagName}
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body py-4 px-5">
                        {item.Content}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={"flex-1 pl-24"}>
            <img src={imgComputerTop} alt={"computer top"} />
          </div>
        </div>
      </div>
      {/* END FAQ Section*/}

      {/* START Hero Info Section */}
      <HeroSection />
      {/* END Hero Info Section */}
    </div>
  );
};

export default HomePage;
