import {Link} from "react-router-dom";
import SortedServiceAreaList from "../helper/ServicesAreaHelper";
import SortedServiceNameList from "../helper/ServiceNameHelper";

const NavMenu = () => {
    return (
        <ul className="group flex flex-col p-2  mt-4  rounded-lg border  md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0">
            <li className={"group-active:text-j2green"}>
                <a
                    href="/"
                    className="hover:text-j2green active:text-j2green block py-2 pr-4 pl-3 text-white text-lg font-bold bg-blue-900 rounded md:bg-transparent md:text-white md:p-0 dark:text-white"
                    aria-current="page"
                >
                    Home
                </a>
            </li>
            <li className={"group-active:text-j2green"}>
                <a
                    href="/our-services"
                    className="hover:text-j2green active:text-j2green text-lg font-bold peer px-5 py-2 text-white"
                    aria-current="page"
                >
                    Services
                </a>
                <div
                    className="hidden peer-hover:flex  hover:flex
                                 w-[280px]
                                 flex-col bg-j2green drop-shadow-md
                                 absolute
                                 mt-1
                                 rounded-sm
                                 text-left
                                 "
                >
                    <ul className={"px-3 py-4"}>
                        {SortedServiceNameList.map((service) => {
                            return (
                                <Link to={`/our-services/${service.Name.toLowerCase()}`}>

                                    <li
                                        key={service.Id}
                                        className="px-3 py-1 font-bold hover:text-white text-j2blue"
                                    >
                                        {service.ServiceName.toUpperCase()}
                                    </li>
                                </Link>
                            );
                        })}
                    </ul>
                </div>
            </li>
            <li>
                <a
                    href="/testimonials"
                    className="hover:text-j2green active:text-j2green text-lg font-bold block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0 dark:text-white"
                    aria-current="page"
                >
                    Testimonials
                </a>
            </li>

            <li className={"group-active:text-j2green"}>
                <a
                    href="/service-area"
                    className="hover:text-j2green active:text-j2green text-lg font-bold peer px-5 py-2 text-white"
                    aria-current="page"
                >
                    Service Area
                </a>
                <div
                    className="hidden peer-hover:flex  hover:flex
                                 w-[150px]
                                 flex-col bg-j2green drop-shadow-md
                                 absolute
                                 mt-1
                                 rounded-sm
                                 text-left
                                 "
                >
                    <ul className={"px-3 py-4"}>
                        {SortedServiceAreaList.filter((item) => item.hide === false).map(
                            (area) => {
                                return (
                                    <Link to={`/service-area/${area.Suburb.toLowerCase()}`}>
                                        <li
                                            key={area.name}
                                            className="px-3 py-1 font-bold hover:text-white text-j2blue w-full"
                                        >
                                            {area.name.toUpperCase()}
                                        </li>
                                    </Link>

                                );
                            }
                        )}
                        <Link to={`/service-area`}>
                            <li className="px-3 py-1 font-bold hover:text-white text-j2blue w-full">
                                MORE AREAS
                            </li>
                        </Link>
                    </ul>
                </div>
            </li>
            <li>
                <a
                    href="/contact"
                    className="hover:text-j2green active:text-j2green text-lg font-bold block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0 dark:text-white"
                    aria-current="page"
                >
                    Contact
                </a>
            </li>
        </ul>
    );
}
    ;

    export default NavMenu;
