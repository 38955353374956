export const serviceArea = [
     {
        name: "Geelong city centre",
        Suburb: "Geelong-city-centre",
        hide: true,
      },
     {
        name: "Geelong",
        Suburb: "Geelong",
        hide: false,
      },
     {
        name: "East Geelong",
        Suburb: "East-Geelong",
        hide: true,
      },
     {
        name: "South Geelong",
        Suburb: "South-Geelong",
        hide: true,
      },
     {
        name: "Geelong West",
        Suburb: "Geelong-West",
        hide: true,
      },
     {
        name: "Drumcondra",
        Suburb: "Drumcondra",
        hide: true,
      },
     {
        name: "Newtown",
        Suburb: "Newtown",
        hide: true,
      },
     {
        name: "Manifold Heights",
        Suburb: "Manifold-Heights",
        hide: true,
      },
     {
        name: "Rippleside",
        Suburb: "Rippleside",
        hide: true,
      },
     {
        name: "Thomson",
        Suburb: "Thomson",
        hide: true,
      },
     {
        name: "Belmont",
        Suburb: "Belmont",
        hide: false,
      },
     {
        name: "Breakwater",
        Suburb: "Breakwater",
        hide: true,
      },
     {
        name: "Herne Hill",
        Suburb: "Herne-Hill",
        hide: true,
      },
     {
        name: "Newcomb",
        Suburb: "Newcomb",
        hide: true,
      },
     {
        name: "Hamlyn Heights",
        Suburb: "Hamlyn-Heights",
        hide: true,
      },
     {
        name: "North Geelong",
        Suburb: "North-Geelong",
        hide: true,
      },
     {
        name: "Whittington",
        Suburb: "Whittington",
        hide: false,
      },
     {
        name: "Highton",
        Suburb: "Highton",
        hide: true,
      },
     {
        name: "Bell Park",
        Suburb: "Bell-Park",
        hide: true,
      },
     {
        name: "St Albans Park",
        Suburb: "St-Albans-Park",
        hide: false,
      },
     {
        name: "Norlane",
        Suburb: "Norlane",
        hide: true,
      },
     {
        name: "Grovedale",
        Suburb: "Grovedale",
        hide: false,
      },
     {
        name: "Wandana Heights",
        Suburb: "Wandana-Heights",
        hide: true,
      },
     {
        name: "Bell Post Hill",
        Suburb: "Bell-Post-Hill",
        hide: true,
      },
     {
        name: "Moolap",
        Suburb: "Moolap",
        hide: true,
      },
     {
        name: "Corio",
        Suburb: "Corio",
        hide: false,
      },
     {
        name: "Armstrong Creek",
        Suburb: "Armstrong-Creek",
        hide: true,
      },
     {
        name: "Lovely Banks",
        Suburb: "Lovely-Banks",
        hide: true,
      },
     {
        name: "Batesford",
        Suburb: "Batesford",
        hide: true,
      },
     {
        name: "Waurn Ponds",
        Suburb: "Waurn-Ponds",
        hide: true,
      },
     {
        name: "Mount Duneed",
        Suburb: "Mount-Duneed",
        hide: true,
      },
     {
        name: "Leopold",
        Suburb: "Leopold",
        hide: true,
      },
     {
        name: "Connewarre",
        Suburb: "Connewarre",
        hide: true,
      },
     {
        name: "Lara",
        Suburb: "Lara",
        hide: false,
      },
     {
        name: "Wallington",
        Suburb: "Wallington",
        hide: true,
      },
     {
        name: "Clifton Springs",
        Suburb: "Clifton-Springs",
        hide: true,
      },
     {
        name: "Barwon Heads",
        Suburb: "Barwon-Heads",
        hide: true,
      },
     {
        name: "Ocean Grove",
        Suburb: "Ocean-Grove",
        hide: true,
      },
     {
        name: "Drysdale",
        Suburb: "Drysdale",
        hide: true,
      },
     {
        name: "Moriac",
        Suburb: "Moriac",
        hide: true,
      },
];
