import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ServicesPage from "./pages/Services/ServicesPage";
import NotFoundPage from "./pages/Errors/NotFoundPage";
import Service from "./pages/Services/Service";
import Contact from "./pages/Contact";
import Testimonials from "./pages/Testimonials";
import ServiceArea from "./pages/ServiceArea";
import AboutUs from "./pages/AboutUs";
import AreaPage from "./pages/AreaPage";
import React from "react";
import useDocumentTitle from "./hook/useDocumentTitle";
import { branch } from "./data/config";
import BookingTermsConditions from "./pages/BookingTermsConditions";

const SiteRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/not-found" element={<NotFoundPage />} />
      <Route path="/our-services" element={<ServicesPage />} />
      <Route path="/our-services/:Name" element={<Service />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/testimonials" element={<Testimonials />} />
      <Route path="/service-area" element={<ServiceArea />} />
      <Route path="/service-area/:Suburb" element={<AreaPage />} />
      <Route path="/booking-terms-and-conditions" element={<BookingTermsConditions />} />
    </Routes>
  );
};

export default SiteRouter;
