import "./App.css";
import ErrorBoundary from "./utils/ErrorBoundary";
import SiteRouter from "./SiteRouter";
import PageHeader from "./components/PageHeader";
import Footer from "./components/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import ReactGA from "react-ga";
import LocationDisabledModal from "./components/LocationDisabledModal";
import {Helmet} from "react-helmet";
import {branch} from "./data/config"

function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_PROPERTY_ID);

  return (
    <ErrorBoundary>
        <Helmet>
            <meta charSet="utf-8" />
            <link rel="canonical" href={"https://www." + branch[0].city.toLowerCase() + "mobilecomputerhelp.com.au"} />
        </Helmet>

      <Router>
        <LocationDisabledModal/>
        <PageHeader />
        <SiteRouter />
        <Footer />
      </Router>
    </ErrorBoundary>
  );
}

export default App;
