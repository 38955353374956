import { services } from "../data/services";
import { Link } from "react-router-dom";
import BookATechnicianButton from "../components/BookATechnicianButton";
import { useState, useEffect } from "react";
import CheckMark from "../components/CheckMark";
import { Pricing } from "../components/Pricing";
import { API } from "../data/API";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import AccountCircle from "@mui/icons-material/AccountCircle";
import HouseIcon from "@mui/icons-material/House";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

import TextField from "@mui/material/TextField";
import useDocumentTitle from "../hook/useDocumentTitle";
import {branch} from "../data/config";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Contact = () => {
  useDocumentTitle(`Contact | ${branch[0].city} Mobile Computer Help`);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("waitSubmit");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = (e, entry) => {
    switch (entry) {
      case "Name":
        setName(e.target.value);
        break;
      case "Phone":
        setPhone(e.target.value);
        break;
      case "Email":
        setEmail(e.target.value);
        break;
      case "Address":
        setAddress(e.target.value);
        break;
      case "Message":
        setMessage(e.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus("Sending");
    const formData = {
      name: name,
      phone: phone,
      email: email,
      address: address,
      message: message,
    };

    await API.post(`${BASE_URL}/Contact`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        setStatus("Sent");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setStatus("Error");
        console.log("error:", err);
      });
  };

  const handleReset = () => {
    setStatus("waitSubmit");
    setName("");
    setPhone("");
    setEmail("");
    setAddress("");
    setMessage("");
  };

  const getSubmitButtonText = () => {

    let buttonText = "";
    if (status === "waitSubmit") {
      buttonText = "Submit";
    } else if (status === "Sending") {
      buttonText = "Sending...";
    } else if (status === "Sent") {
      buttonText = "Sent";
    } else {
      buttonText = "Try Later";
    }

    return buttonText;
  }
  return (
    <div
      className={
        "flex flex-col flex-wrap mx-0 my-0 py-0 px-0 justify-center items-center w-screen"
      }
    >
      {/* START Banner Section */}
      <div
        className={
          "flex flex-col mx-auto left-0 h-auto py-12 pl-20 pr-6 xl:px-48 xl:h-96 bg-slider-1 bg-cover w-full text-white  xl:pt-32 overflow-hidden"
        }
      >
        <div className={"mx-auto mx:pt-12 mx:pt-16 mx:pl-48 w-full"}>
          <div className={"text-3xl  xl:text-6xl font-extrabold"}>
            Contact Us
          </div>
          <div className={"flex flex-row text-sm pt-8"}>
            <span>
              <a
                href={"/"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Home
              </a>
            </span>
            <span className={"mx-2 text-xl"}>/</span>
            <span className={"text-xl"}>Contact Us</span>
          </div>
        </div>
      </div>
      {/* END Banner Section */}

      <div
        className={
          "flex flex-col md:flex-row  mx-auto my-0 w-full md:px-8  py-8 xl:px-20 xl:pt-20 justify-center items-center self-center md:self-start"
        }
      >
        {/* START Pricing Section */}
        <div
          className={
            "order-2 md:order-1 w-full xl:w-1/2 md:pl-16 self-center items-center justify-center"
          }
        >
          <Pricing />
        </div>
        {/* END Pricing Section */}

        {/* START Contact Form Section */}
        <div
          className={
            "order-1 md:order-2 flex flex-col my-0 w-full xl:w-1/2  mx-0 my-12 md:px-2 xl:px-4 self-center justify-center items-center xl:items-start md:justify-start md::self-start"
          }
        >
          <div
            className={
              "flex flex-row text-5xl font-bold my-0 text-j2blue text-center mb-4 xl:mb-4 mb-4"
            }
          >
            <span className={"text-j2green"}>&#123;</span>Get In Touch
          </div>
          <div className={"flex flex-row flex-wrap py-2 px-2 sm:px-0"}>
            If you’d like to contact us, please do so by phone on (03) 5309 0439
            or by using the form below.
          </div>



          <form
            className={
              "w-[300px] md:w-[360px] lg:w-[450px]  xl:w-[600px] mt-2 font-bold border border-4 border-gray-400 hover:border-blue-700 rounded-2xl px-2 md:px-8 py-8 mx-0"
            }
            onSubmit={(e) => handleSubmit(e)}
          >
            {status === "Sent" ?
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative w-full text-md mb-5" role="alert">
                  <span className="block sm:inline">Your message has been sent. We'll be in touch soon!</span>
                </div>
                : null}

            <div className={"flex flex-col"}>
              <TextField
                required
                id="outlined-required"
                label="Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange(e, "Name")}
                pattern={"^[a-zA-Z\\s]{1,50}$"}
              />
            </div>
            <div className={"flex flex-col"}>
              <TextField
                id="outlined-basic"
                label="Address"
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HouseIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange(e, "Address")}
                pattern={
                  "^[a-zA-Z0-9\\s]*([a-zA-Z\\s][0-9\\s]|[0-9\\s][a-zA-Z\\s])[a-zA-Z0-9\\s]*$"
                }
              />
            </div>
            <div className={"flex flex-col"}>
              <TextField
                required
                id="outlined-required"
                label="Email"
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                type="email"
                onChange={(e) => handleChange(e, "Email")}
                pattern={
                  "/^([A-Za-z0-9_\\-\\.])+\\@([A-Za-z0-9_\\-\\.])+\\.([A-Za-z]{2,8})$/"
                }
              />
            </div>
            <div className={"flex flex-col"}>
              <TextField
                required
                id="outlined-required"
                label="Phone"
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange(e, "Phone")}
                pattern={"^[0-9()\\s]+$"}
              />
            </div>
            <div className={"flex flex-col"}>
              <TextField
                id="outlined-multiline-static"
                label="Message"
                InputProps={{
                  startAdornment: (
                    <div>
                      <div className={"relative bottom-14"}>
                        <InputAdornment position="start">
                          <SpeakerNotesIcon />
                        </InputAdornment>
                      </div>
                    </div>
                  ),
                }}
                margin="normal"
                multiline
                minRows={6}
                onChange={(e) => handleChange(e, "Message")}
              />
            </div>

            <div
              className={
                "flex flex-col sm:flex-row justify-evenly items-center self-center"
              }
            >
              <LoadingButton
                variant="contained"
                size="medium"
                type="submit"
                sx={{ width: 130, marginTop: 2 }}
                endIcon={<SendOutlinedIcon />}
                loading={loading}
                loadingPosition="end"
              >
                {getSubmitButtonText()}
              </LoadingButton>

              <Button
                variant="contained"
                type="reset"
                size="medium"
                sx={{ width: 130, marginTop: 2 }}
                onClick={handleReset}
                endIcon={<RestartAltOutlinedIcon />}
              >
                Reset
              </Button>
            </div>
          </form>
        </div>
        {/* END  Contact Form Section */}
      </div>

      {/* START Service List Section */}
      <div
        className={
          "w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4  xl:grid-flow-row-dense p-4 md:p-8 text-gray-500 font-bold text-lg  bg-gray-200 bg-cover "
        }
      >
        {services.map((service) => {
          return (
            <div key={service.Id} className="my-1">
              <CheckMark Color={" text-j2blue"} />
              <Link
                to={`/our-services/${service.Name.toLowerCase()}`}
                className={"hover:text-j2green hover:underline ml-2"}
              >
                {service.ServiceName.toUpperCase()}
              </Link>
            </div>
          );
        })}
      </div>
      {/* END Service List Section */}

      {/* START Hero Info Section */}
      <div
        className={
          "bg-black bg-cover bg-center  flex flex-col " +
          "flex-wrap mt-auto md:py-24 md:pl-24  2xl:py-48  right-0 left-0 mx-0 py-8 w-full md:h-full items-center md:items-start"
        }
      >
        <div
          className={
            "pl-4 xl:pl-10 text-center align-middle  flex flex-col items-center justify-center"
          }
        >
          <div className={"w-full text-left"}>
            <h1 className={"text-3xl  md:text-6xl font-bold text-white"}>
              <span className={"text-j2green"}>&#123;</span> Your Computer
              Doesn't Boot?
            </h1>
          </div>
          <div className={"w-full mt-6 text-left text-white text-lg"}>
            Simply make a booking for one of our technicians to visit you, and
            it will be good as new in no time!
          </div>
          <div className={"w-full mt-8 text-left"}>
            <BookATechnicianButton />
          </div>
        </div>
      </div>
      {/* END Hero Info Section */}
    </div>
  );
};

export default Contact;
