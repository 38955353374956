import { branch } from "../data/config";
import comingsoon from "../img/comingsoon.svg";
const LocationDisabledModal = (props) => {
    if (process.env.REACT_APP_LOCATION_DISABLED === "false")
        return;

    return (
        <div className="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 md:w-1/4">
                        <div>
                            <div
                                className="mx-auto flex h-48 w-48 items-center justify-center">
                                <img src={comingsoon} alt={"Coming Soon"} />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <div className="font-semibold leading-6 text-gray-900 text-2xl" id="modal-title">Exciting News!</div>
                                <div className="mt-4">
                                    <div className="text-gray-500 text-lg">{branch[0].city} Mobile Computer Help is on its way to your city.</div>
                                    <div className="text-gray-500 text-lg mt-4 font-bold">We’re almost ready to launch, so stay tuned!</div>
                                    <div className="text-gray-500 text-md mt-4">Interested in working with us? Contact us at <a href={"mailto:hello@" + branch[0].city.toLowerCase() + "mobilecomputerhelp.com.au"}>hello@{branch[0].city.toLowerCase()}mobilecomputerhelp.com.au</a>.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

LocationDisabledModal.defaultProps = {
    Styles: "",
};

export default LocationDisabledModal;
