import { branch } from "../data/config";

const BookATechnicianButton = (props) => {
  const backgroundColor = !props.InvertColor
    ? "bg-transparent"
    : "bg-j2blue";

  return (
    <a
      type="button"
      href={branch[0].bookingUrl}
      className={`hover:bg-j2green
                focus:ring-4
                focus:outline-none
                focus:ring-blue-300
                font-medium rounded-lg
                text-sm
                px-5
                py-2.5
                text-center
                mr-3
                md:mr-0
                border-white
                border-2
                text-white
                transition-all
                font-bold
                ${backgroundColor}
                ${props.Styles}
                `}
    >
      Book A Technician
    </a>
  );
};

BookATechnicianButton.defaultProps = {
  InvertColors: false,
  Styles: "",
};

export default BookATechnicianButton;
