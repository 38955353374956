import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <div
      className={
        "flex flex-row font-bold text-xl text-gray-700 mt-8 mb-4 items-center content-center justify-evenly w-80"
      }
    >
      <a className="page-link" onClick={prevPage} href="#">
        <FontAwesomeIcon
          icon={faChevronLeft}
          size={"lg"}
          className={"text-j2blue hover:text-j2green"}
        />
      </a>

      {pageNumbers.map((pgNumber) => (
        <div key={pgNumber}>
          <a
            onClick={() => setCurrentPage(pgNumber)}
            className={
              "text-j2blue text-xl font-bold hover:text-j2green"
            }
            href="#"
          >
            {pgNumber}
          </a>
        </div>
      ))}

      <a className="page-link" onClick={nextPage} href="#">
        <FontAwesomeIcon
          icon={faChevronRight}
          size={"lg"}
          className={
            "text-j2blue hover:text-j2green active:text-j2green"
          }
        />
      </a>
    </div>
  );
};

export default Pagination;
