import { branch } from "../data/config";

const HeroSlider = () => {
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide relative carousel-fade w-screen m-0 right-0 left-0 max-h-96
       "
      data-bs-ride="carousel"
    >
      <div
        className="carousel-indicators absolute hidden xl:flex  right-0 top-32 justify-center p-0
         md:flex md:left-0 md:right-0 md:top-80 2xl:hidden
      "
      >
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>

        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>

      <div className="carousel-inner absolute w-screen md:overflow-hidden  ">
        <div className="carousel-item  active relative float-left w-full h-128">
          <div
            className={
              "flex w-full h-128 bg-slider-1 bg-cover h-[200px]  md:h-[380px] "
            }
          ></div>
          <div
            className={
              "absolute left-20 top-3 sm:top-6 md:top-10  z-40 text-white md:left-40 2xl:left-80"
            }
          >
            <div
              className={"text-2xl md:text-5xl  xl:text-7xl  font-extrabold"}
              style={{ textShadow: "black 0.1em 0.1em 0.3em" }}
            >
              Computer Repairs
            </div>
            <div
              className={"text-lg mt-0 md:mt-3 md:text-4xl font-bold"}
              style={{ textShadow: "black 0.1em 0.1em 0.3em" }}
            >
              In Your Home
            </div>

            <a
              type="button"
              href={"/our-services/Computer-Repairs"}
              className={
                "focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold " +
                "hover:bg-white hover:border-2 border-j2bue " +
                "rounded-full text-j2blue py-1.5 px-3 mt-1 text-center " +
                "bg-j2green text-sm xl:text-xl xl:px-8 xl:py-3 md:mt-6 xl:mt-10"
              }
            >
              Find Out More
            </a>
          </div>
          <div
            className={
              "carousel-caption text-xs md:text-xl  block absolute left-20  bottom-1  md:bottom-8 text-left whitespace-pre-wrap md:left-40 2xl:left-80"
            }
          >
            {branch[0].openTime}
          </div>
        </div>

        <div className="carousel-item   relative float-left w-full h-128">
          <div
            className={
              "flex w-full h-128 bg-slider-2 bg-cover h-[200px]  md:h-[380px] "
            }
          ></div>
          <div
            className={
              "absolute left-20 top-3 sm:top-6 md:top-10  z-40 text-white md:left-40 2xl:left-80"
            }
          >
            <div
              className={"text-2xl md:text-5xl  xl:text-7xl  font-extrabold"}
              style={{ textShadow: "black 0.1em 0.1em 0.3em" }}
            >
              Specialist Data Recovery
            </div>
            <div
              className={"text-lg mt-0 md:mt-3 md:text-4xl font-bold"}
              style={{ textShadow: "black 0.1em 0.1em 0.3em" }}
            >
              In Our Clean Labs
            </div>

            <a
              type="button"
              href={"/our-services/Computer-Repairs"}
              className={
                "focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold " +
                "hover:bg-white hover:border-2 border-j2bue " +
                "rounded-full text-j2blue py-1.5 px-3 mt-1 text-center " +
                "bg-j2green text-sm xl:text-xl xl:px-8 xl:py-3 md:mt-6 xl:mt-10 "
              }
            >
              Find Out More
            </a>
          </div>
          <div
            className={
              "carousel-caption text-xs md:text-xl  block absolute bottom-1 left-20 md:bottom-8 text-left whitespace-pre-wrap md:left-40 2xl:left-80"
            }
          >
            {branch[0].openTime}
          </div>
        </div>

        <div className="carousel-item   relative float-left w-full h-128">
          <div
            className={
              "flex w-full h-128 bg-slider-3 bg-cover h-[200px]  md:h-[380px] "
            }
          ></div>
          <div
            className={
              "absolute left-20 top-3 sm:top-6 md:top-10  z-40 text-white md:left-40 2xl:left-80"
            }
          >
            <div
              className={"text-2xl md:text-5xl  xl:text-7xl  font-extrabold"}
              style={{ textShadow: "black 0.1em 0.1em 0.3em" }}
            >
              Virus Removal
            </div>
            <div
              className={"text-lg mt-0 md:mt-3 md:text-4xl font-bold"}
              style={{ textShadow: "black 0.1em 0.1em 0.3em" }}
            >
              In Your Home
            </div>

            <a
              type="button"
              href={"/our-services/Computer-Repairs"}
              className={
                "focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold " +
                "hover:bg-white hover:border-2 border-j2bue " +
                "rounded-full text-j2blue py-1.5 px-3 mt-1 text-center " +
                "bg-j2green text-sm xl:text-xl xl:px-8 xl:py-3 md:mt-6 xl:mt-10 "
              }
            >
              Find Out More
            </a>
          </div>

          <div
            className={
              "carousel-caption  text-xs md:text-xl  block absolute bottom-1 left-20 md:bottom-8 text-left whitespace-pre-wrap md:left-40 2xl:left-80"
            }
          >
            {branch[0].openTime}
          </div>
        </div>

        <button
          className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0 xl:-left-20"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden ">Previous</span>
        </button>

        <button
          className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0 xl:-right-20"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden ">Next</span>
        </button>
      </div>
    </div>
  );
};

export default HeroSlider;
