export const branch = [
  {
    city: "Geelong",
    address: "Shop T06, 25-51 Learmonth Road Wendouree VIC 3350",
    phoneNumber: "(03) 5309 0439",
    emailAddress: "hello@geelongmobilecomputerhelp.com.au",
    bookingUrl:
      "https://book.servicem8.com/request_service_booking?strVendorUUID=89c5ad34-7d88-48e4-848d-a7b289704d6b#1092611f-7a26-47df-bff3-217d92466fbb",
    openTime: (
      <div>
        <div className={"lg:flex lg:flex-row sm:flex sm:flex-col"}>
          <div>We Operate Saturday, </div>
          <div>10:00 am to 5:00 pm</div>
        </div>
      </div>
    ),
  },
];
