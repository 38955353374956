import logoimg from "../img/logo_white.png";

const Logo = (props) => {
  return (
    <a href="/">
      <img src={logoimg} alt={"logo"} style={{ "max-width": props.MaxWidth }} />
    </a>
  );
};

Logo.defaultProps = {
  MaxWidth: "200px",
};

export default Logo;
