import {Helmet} from "react-helmet";

export default function ChatAppComponent() {
    const phoneServerUrl = process.env.REACT_APP_3CX_PHONE_SERVER_URL;
    const partyLine = process.env.REACT_APP_3CX_PARTY_LINE;

    return (
        <>
            <Helmet>
                <script
                    src="https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js"
                    id="tcx-callus-js"
                    charSet="utf-8"></script>
            </Helmet>
            <call-us-selector phonesystem-url={phoneServerUrl} party={partyLine}></call-us-selector>
        </>
    )
}