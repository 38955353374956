import { services } from "../../data/services";
import { branch } from "../../data/config";
import { Link } from "react-router-dom";
import staff from "../../img/staff_bg.png";
import BookATechnicianButton from "../../components/BookATechnicianButton";
import { useEffect } from "react";
import { HeroSection } from "../../components/HeroSection";
import useDocumentTitle from "../../hook/useDocumentTitle";

const ServicesPage = () => {
  useDocumentTitle(`Services | ${branch[0].city} Mobile Computer Help`);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className={
        "flex flex-col flex-wrap mx-0 my-0 py-0 px-0 justify-center items-center w-screen"
      }
    >
      {/* START Banner Section */}
      <div
        className={
          "flex flex-col left-0 h-auto py-12 pl-20 pr-6 xl:px-48 xl:h-96 bg-slider-1 bg-cover w-full text-white  xl:pt-32 overflow-hidden"
        }
      >
        <div className={"mx:pt-12 mx:pt-16 mx:pl-48 w-full"}>
          <div className={"text-3xl  xl:text-6xl font-extrabold"}>
            Our Services
          </div>
          <div className={"flex flex-row text-sm pt-8"}>
            <span>
              <a
                href={"/"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Home
              </a>
            </span>
            <span className={"mx-2 text-xl"}>/</span>
            <span>
              <a
                href={"/our-services"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Services
              </a>
            </span>
          </div>
        </div>
      </div>
      {/* END Banner Section */}
      {/* START Introduction Section */}
      <div
        className={
          "flex flex-col md:flex-row my-0 w-full px-8  py-8 xl:px-36 xl:pt-20 items-start justify-start  content-center "
        }
      >
        <div className={"order-2 md:order-1 xl:px-6 py-10 w-full xl:w-1/2"}>
          <img src={staff} alt={"staff"} />
        </div>

        <div
          className={
            "order-1 md:order-2 flex flex-col xl:w-1/2 text-4xl  text-j2blue justify-center"
          }
        >
          <div className={"w-full"}>
            <div>
              <span className={"text-j2green font-extrabold mr-2"}>
                &#123;
              </span>
              <span className={"text-bold font-extrabold"}>Our Services</span>
            </div>

            <div
              className={
                "mt-8 text-gray-600 text-base xl:text-xl  whitespace-pre-wrap pr-10 font-bold"
              }
            >
              <p>
                The team at {branch[0].city} Mobile Computer Help has a number
                of services available to you. The majority of our services are
                performed in your home. Please select a service below to find
                out more.
              </p>
              <br />
              <p>
                We are proudly a {branch[0].city} owned and operated business,
                and look forward to assisting you with whatever issue you are
                faced with.
              </p>
              <br />
              <p>
                If you have any questions, please don’t hesitate to contact us.
              </p>
            </div>

            <div className={"mt-4 mb-4"}>
              <BookATechnicianButton InvertColor={true} />
            </div>
          </div>
        </div>
      </div>
      {/* END Introduction Section */}
      {/* START Services List Section */}
      <div className={"w-screen bg-gray-200 h-auto bg-cover md:px-12"}>
        <div
          className={
            "mx-0 py-16 justify-center items-center justify-center text-lg md:text-3xl px-8" +
            " grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 grid-flow-row font-semibold text-j2blue"
          }
        >
          {services.map((service) => {
            return (
              <div
                key={service.Id}
                className={
                  "bg-gradient-to-t from-gray-500 to-gray-300  px-4 md:px-8 py-2 rounded-2xl hover:text-gray-200"
                }
              >
                <Link to={`/our-services/${service.Name.toLowerCase()}`}>
                  {service.ServiceName}
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      {/* END Services List Section */}
      {/* START Hero Info Section */}
      <HeroSection />
      {/* END Hero Info Section */}
    </div>
  );
};

export default ServicesPage;
