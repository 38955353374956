import { services } from "../data/services";
import { branch } from "../data/config";
import BookATechnicianButton from "../components/BookATechnicianButton";
import repair from "../img/repair.jpg";
import { Link } from "react-router-dom";
import CheckMark from "../components/CheckMark";
import { useEffect } from "react";
import { HeroSection } from "../components/HeroSection";
import useDocumentTitle from "../hook/useDocumentTitle";

const AboutUs = () => {
  useDocumentTitle(`About Us | ${branch[0].city} Mobile Computer Help`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className={
        "flex flex-col flex-wrap mx-0 my-0 py-0 px-0 justify-center items-center w-screen"
      }
    >
      {/* START Banner Section */}
      <div
        className={
          "flex flex-col mx-auto left-0 h-auto py-12 pl-20 pr-6 xl:px-48 xl:h-96 bg-slider-1 bg-cover w-full text-white  xl:pt-32 overflow-hidden"
        }
      >
        <div className={"mx-auto mx:pt-12 mx:pt-16 mx:pl-48 w-full"}>
          <div className={"text-3xl  xl:text-6xl font-extrabold"}>
            About {branch[0].city} Mobile Computer Help
          </div>
          <div className={"flex flex-row text-sm pt-8"}>
            <span>
              <a
                href={"/"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Home
              </a>
            </span>
            <span className={"mx-2 text-xl"}>/</span>
            <span className={"text-xl"}>
              About {branch[0].city} Mobile Computer Help
            </span>
          </div>
        </div>
      </div>
      {/* END Banner Section */}

      {/* START Main content Section */}
      <div
        className={
          "flex flex-col md:flex-row  mx-auto my-0 w-full px-8  py-8 xl:px-36 xl:pt-20 items-start justify-start  content-center "
        }
      >
        <div
          className={
            "flex flex-col xl:w-1/2 text-4xl  text-j2blue justify-center"
          }
        >
          <div className={"w-full"}>
            <div>
              <span className={"text-j2green font-extrabold mr-2"}>
                &#123;
              </span>
              <span className={"text-bold font-extrabold"}>Who We Are</span>
            </div>

            <div
              className={
                "mt-8 text-gray-600 text-sm whitespace-pre-wrap pr-10 font-bold"
              }
            >
              <p>
                {branch[0].city} Mobile Computer Help is made up of a team who
                love what they do. We invest time into ensuring each of our
                technicians is well trained and well versed in many
                technologies. We are a locally owned, family run business, with
                the sole focus of providing quality mobile computer help in and
                around {branch[0].city}.
              </p>
              <br />
              <p>
                Our business started on the back of a pushbike, back in Canberra
                when Matt was 13. Over the years the service has been provided
                in different towns and cities. After Matt and his family moved
                to Ballarat in 2017, he saw a need for in home, after hours
                computer help. Since then our local business has been growing
                quite humbly, and we've been fortunate to meet some amazing
                customers.
              </p>
              <br />
              <p>
                We strive to make the process as easy as possible for you, from
                booking to completion. Our technicians also work to minimise the
                cost to you, only suggesting work that is essential and
                providing you options for the remaining work.
              </p>
              <br />
              <p>
                If you're looking mobile computer help, let {branch[0].city} Mobile
                Computer Help be your first port of call.
              </p>
            </div>
            <div
              className={"flex flex-col mt-6 text-gray-500 font-bold text-sm"}
            >
              <ul>
                {services.map((service) => {
                  return (
                    <li key={service.Id} className="  my-1">
                      <CheckMark Color={"text-j2blue"} />
                      <Link
                        to={`/our-services/${service.Name.toLowerCase()}`}
                        className={"ml-2 hover:text-j2green hover:underline"}
                      >
                        {service.ServiceName.toUpperCase()}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={"mt-4 mb-4"}>
              <BookATechnicianButton InvertColor={true} />
            </div>
          </div>
        </div>

        <div className={"xl:px-6 py-10 w-full xl:w-1/2"}>
          <img src={repair} alt={"repair computer"} />
        </div>
      </div>
      {/* END Main content Section */}

      {/* START Hero Info Section */}
      <HeroSection />
      {/* END Hero Info Section */}
    </div>
  );
};

export default AboutUs;
