export const faqs = [
    {
        Summary: 'Which methods of payment do you accept?',
        Content: 'You can pay us by credit, debit card, direct deposit or cash. You will receive a tax invoice after the technician finishes up.'
    },
    {
        Summary: 'What about phones?',
        Content: 'If you’re having an issue with an app, or your phone isn’t sync’ing to your computer, we can help.\n' +
            '\n' +
            'If you require a screen replacement or your phone is physically damaged, please visit Ballarat iPhones (they have online booking too!)'
    },
    {
        Summary: 'Can you help set up a new computer ?',
        Content: 'Yes we can. We can even help you pick your new computer! Depending on what you require, we may direct you to a store in Ballarat, or we can source the computer for you.'
    },
    {
        Summary: 'Are all repairs done at my home?',
        Content: 'Wherever possible, all repairs are done in your home. In some cases however your computer may need to visit our computer hospital. Our technicians will generally recommend this if it will result in a lower cost for you. If you have concerns around your computer leaving your home, please speak to the technician as we can generally accommodate for special requirements (such as government and sensitive data).'
    },
    {
        Summary: 'Can You Issue Insurance Letters?',
        Content: 'Yes we can. We must inspect any claimed damage before we can prepare an insurance report. Our fee is payable prior to you receiving the insurance report.'
    },
]

