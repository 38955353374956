import { useState } from "react";

export default function BurgerMenu() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="flex items-center justify-between ">
      <nav>
        <section className="MOBILE-MENU flex md:hidden">
          <div
            className="HAMBURGER-ICON space-y-2"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-1 w-10 animate-pulse bg-white"></span>
            <span className="block h-1 w-10 animate-pulse bg-white"></span>
            <span className="block h-1 w-10 animate-pulse bg-white"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div
              className="absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="flex flex-col items-center justify-between min-h-[300px] text-3xl font-bold text-j2bue">
              <li className="border-b border-blue-400 my-8 uppercase">
                <a href="/">Home</a>
              </li>
              <li className="border-b border-j2bue my-8 uppercase">
                <a href="/our-services">Services</a>
              </li>
              <li className="border-b border-j2bue my-8 uppercase">
                <a href="/testimonials">Testimonials</a>
              </li>
              <li className="border-b border-j2bue my-8 uppercase">
                <a href="/service-area">Service Area</a>
              </li>
              <li className="border-b border-j2bue my-8 uppercase">
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </section>
      </nav>
      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
    </div>
  );
}
