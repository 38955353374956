import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";

const HomepageService = (props) => {
  const textAreaPadding = props.InvertArrow ? "pl-12" : "pr-12";

  return (
    <Link key={props.key} to={`${props.URL}`} className={"justify-start"}>
      <div className={"flex flex-col xl:flex-row mt-4 xl:mt-8 "}>
        {props.InvertArrow && (
          <div className={"hidden xl:flex flex-none pt-1.5"}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={"text-j2blue"}
            />
          </div>
        )}
        <div className={"flex-1" + textAreaPadding}>
          <div className={"font-bold  xl:mx-6 hover:text-j2green"}>
            {props.ServiceName.toUpperCase()}
          </div>
          <div className={"mt-2  xl:mx-6"}>{props.Summary}</div>
        </div>
        {!props.InvertArrow && (
          <div className={"hidden xl:flex flex-none pt-1.5"}>
            <FontAwesomeIcon
              icon={faChevronRight}
              className={"text-j2blue"}
            />
          </div>
        )}
      </div>
    </Link>
  );
};

HomepageService.defaultProps = {
  ServiceName: "",
  Summary: "",
  InvertArrow: false,
};

export default HomepageService;
