import { services } from "../data/services";
import { Link, useParams } from "react-router-dom";
import BookATechnicianButton from "../components/BookATechnicianButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComputer, faPhone } from "@fortawesome/pro-solid-svg-icons";
import { useEffect } from "react";
import NotFoundPage from "./Errors/NotFoundPage";
import CheckMark from "../components/CheckMark";
import web_devices from "../img/web_devices.png";
import { HeroSection } from "../components/HeroSection";
import SortedServiceAreaList from "../helper/ServicesAreaHelper";
import useDocumentTitle from "../hook/useDocumentTitle";
import {branch} from "../data/config";

const AreaPage = () => {
  let params = useParams();
  const Area = SortedServiceAreaList.find(
    (area) => area.Suburb.toLowerCase() === params.Suburb.toLowerCase()
  );

  useDocumentTitle(`Computer Repairs In ${Area.name} | ${branch[0].city} Mobile Computer Help`)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.Suburb]);

  return Area ? (
    <div
      className={
        "flex flex-col flex-wrap mx-0 my-0 py-0 px-0 justify-center items-center w-screen"
      }
    >
      {/* START Banner Section */}
      <div
        className={
          "flex flex-col mx-auto left-0 h-auto py-12 pl-20 pr-6 xl:px-48 xl:h-96 bg-slider-1 bg-cover w-full text-white  xl:pt-32 overflow-hidden"
        }
      >
        <div className={"mx-auto mx:pt-12 mx:pt-16 mx:pl-48 w-full"}>
          <div className={"text-3xl  xl:text-6xl font-extrabold"}>
            {`Computer Repairs in ${Area.name}`}
          </div>
          <div className={"flex flex-row text-sm pt-8"}>
            <span>
              <a
                href={"/"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Home
              </a>
            </span>
            <span className={"mx-2 text-xl"}>/</span>
            <span>
              <a
                href={"/service-area"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Service Area
              </a>
            </span>

            <span className={"mx-2 text-xl"}>/</span>
            <span className={"text-xl"}> {Area.name}</span>
          </div>
        </div>
      </div>
      {/* END Banner Section */}

      {/* START Introduction Section */}
      <div
        className={
          "flex flex-col md:flex-row  mx-auto my-0 w-full px-8  py-8 xl:px-36 xl:pt-20 items-start justify-start  content-center "
        }
      >
        <div className={"order-2 xl:order-1 xl:px-6 py-10 w-full xl:w-1/2"}>
          <img src={web_devices} alt={web_devices} />
        </div>

        <div
          className={
            "order-1 xl:order-2 flex flex-col xl:w-1/2 text-4xl  text-j2blue justify-center"
          }
        >
          <div className={"w-full"}>
            <div>
              <span className={"text-j2green font-extrabold mr-2"}>
                &#123;
              </span>
              <span className={"text-bold font-extrabold"}>
                If you need mobile computer help in {Area.name}, we’ve got you
                covered.
              </span>
            </div>

            <div
              className={
                "mt-8 text-gray-600 text-base xl:text-xl  whitespace-pre-wrap pr-10 font-bold"
              }
            >
              Our trained and experienced computer technicians can handle any
              computer repair problem that your PC or Mac will throw at them in{" "}
              {Area.name}. Anything to do with computers, tablets, websites,
              email, data recovery and phones, we have the solution! We can help
              you with a wide range of computer services in and around{" "}
              {Area.name} and computer maintenance assistance including:
            </div>

            <div className={"mt-4 mb-4"}>
              <BookATechnicianButton InvertColor={true} />
            </div>
          </div>
        </div>
      </div>
      {/* END Introduction Section */}

      {/* START Service List Section */}
      <div
        className={
          "grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-4 grid-flow-row mb-6 p-4 md:p-8 text-gray-500 font-bold text-lg bg-gray-200 bg-cover "
        }
      >
        {services.map((service) => {
          return (
            <div key={service.Id} className="my-1">
              <CheckMark Color={"text-j2blue"} />
              <Link
                to={`/our-services/${service.Name.toLowerCase()}`}
                className={"hover:text-j2green hover:underline ml-2 "}
              >
                {service.ServiceName.toUpperCase()}
              </Link>
            </div>
          );
        })}
      </div>
      {/* END Service List Section */}

      {/* BEGIN How To Book Section */}
      <div
        className={
          "flex flex-col flex-auto justify-center items-center w-screen mb-8"
        }
      >
        <h1
          className={
            "text-4xl md:text-6xl font-bold text-j2blue text-center"
          }
        >
          <span className={"text-j2green"}>&#123;</span> Two Simple Ways To
          Book
        </h1>

        <div
          className={
            "flex flex-col md:flex-row md:justify-around w-1/2 ml-auto mr-auto mt-8"
          }
        >
          <div className={"flex-1 text-center py-6"}>
            <FontAwesomeIcon
              icon={faPhone}
              size={"4x"}
              className={"text-j2blue"}
            />
            <br />
            <div className={"mt-2 text-2xl"}>
              Call Us On
              <br />
              <a href={"tel:61353090469"}>(03) 5309 0439</a>
            </div>
          </div>

          <div className={"flex-1 text-center py-6"}>
            <FontAwesomeIcon
              icon={faComputer}
              size={"4x"}
              className={"text-j2blue"}
            />
            <br />
            <div className={"mt-2 text-2xl"}>
              Book Online
              <br />
              <BookATechnicianButton InvertColor={true} />
            </div>
          </div>
        </div>
      </div>
      {/* END How To Book Section */}

      {/* START Hero Info Section */}
      <HeroSection />
      {/* END Hero Info Section */}
    </div>
  ) : (
    <NotFoundPage />
  );
};

export default AreaPage;
