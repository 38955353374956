import Logo from "./Logo";
import NavMenu from "./NavMenu";
import BookATechnicianButton from "./BookATechnicianButton";
import BurgerMenu from "./BurgerMenu";

const PageHeader = () => {
  return (
    <nav
      className="bg-j2blue pt-10 pb-4 mx-o sm:px-4 md:pt-4 sticky top-0 w-screen z-10 left-0 right-0
    max-h-60 md:h-32 h-auto flex  flex-auto  flex-row flex-wrap items-center text-center justify-around items-center mx-0"
    >
      <div className={"mb-4 md:my-auto"}>
        <Logo MaxWidth={"200px"} />
      </div>

      <div className="flex my-4">
        <BurgerMenu />
      </div>

      <div
        className="hidden justify-between items-center w-full md:flex md:w-auto group"
        id="navbar-sticky"
      >
        <NavMenu />
      </div>

      <div className={"hidden lg:flex"}>
        <BookATechnicianButton />
      </div>
    </nav>
  );
};

export default PageHeader;
