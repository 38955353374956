import { services } from "../data/services";
import { Link } from "react-router-dom";
import BookATechnicianButton from "../components/BookATechnicianButton";
import CheckMark from "../components/CheckMark";
import {branch} from "../data/config";
import useDocumentTitle from "../hook/useDocumentTitle";

const Contact = () => {

  useDocumentTitle(`Booking Terms and Conditions | ${branch[0].city} Mobile Computer Help`);

  return (
    <div
      className={
        "flex flex-col flex-wrap mx-0 my-0 py-0 px-0 justify-center items-center w-screen"
      }
    >
      {/* START Banner Section */}
      <div
        className={
          "flex flex-col mx-auto left-0 h-auto py-12 pl-20 pr-6 xl:px-48 xl:h-96 bg-slider-1 bg-cover w-full text-white  xl:pt-32 overflow-hidden"
        }
      >
        <div className={"mx-auto mx:pt-12 mx:pt-16 mx:pl-48 w-full"}>
          <div className={"text-3xl  xl:text-6xl font-extrabold"}>
            Booking Terms & Conditions
          </div>
          <div className={"flex flex-row text-sm pt-8"}>
            <span>
              <a
                href={"/"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Home
              </a>
            </span>
            <span className={"mx-2 text-xl"}>/</span>
            <span className={"text-xl"}>Booking Terms & Conditions</span>
          </div>
        </div>
      </div>
      {/* END Banner Section */}

     <div className={
       "flex flex-col md:flex-row  mx-auto my-0 w-full px-8  py-8 xl:px-36 xl:pt-20 2xl:w-4/5  items-start justify-start  content-center "
     }>
       <div className="post_content entry-content">
         <p>By proceeding with your booking, you are automatically agreeing to the following terms and conditions in
           whole. {branch[0].city} Mobile Computer Help does not vary its terms on a per customer or per job basis.</p>
         <p><strong>&nbsp;</strong></p>
         <ol>
           <li><strong>Interpretation</strong>
             <ul>
               <li>In these terms and conditions:
                 <ul>
                   <li>“Company” means the entity stated in the Application for Credit and any related body corporate as
                     defined in the <em>Corporations Act</em> 2001 (Cth) and its successors and assigns.
                   </li>
                   <li>“Customer” means the entity stated in the Application, its successors and assigns.</li>
                   <li>“Goods” means goods (as that term is defined under the PPSA) supplied to the Customer (and where
                     the context so permits includes Services).
                   </li>
                   <li>“Governing State” means the State or Territory where the Company has its principal place of
                     business.
                   </li>
                   <li>“PPSA” means the <em>Personal Property Securities Act 2009</em> (Cth).</li>
                   <li>“ROT security interest” means a retention of title security interest under a “conditional
                     contract” as defined under the PPSA.
                   </li>
                   <li>“Services” means all services supplied to the Customer and includes any advice or
                     recommendations, intellectual or intangible property under the PPSA (and where the context so
                     permits includes any supply of Goods).
                   </li>
                 </ul>
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="2">
           <li><strong>Delivery and Risk</strong>
             <ul>
               <li>Should the Company elect to post any Goods or organise delivery of Goods, the Customer’s risk of loss
                 or damage to such Goods passes to the Customer on the date and at the time that the Goods were ordered,
                 and it is the Customer’s responsibility to ensure these Goods are insured and covered for their time in
                 transit.
               </li>
               <li>The Company will not be responsible for non-delivery or delay in delivery of any Goods and where such
                 non-delivery or delay occurs the Company may deliver the Goods not delivered or delayed at any
                 subsequent time and the Customer must accept and pay for them.
               </li>
               <li>The Customer is not entitled to any compensation from the Company of any nature for any loss, damage
                 or delay.
               </li>
               <li>Except to the extent required by law, the Customer will not be entitled to return any Goods and the
                 Company will not be obliged to accept the return of any Goods whatsoever.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="3">
           <li><strong>Title </strong>
             <ul>
               <li>Until all monies and obligations owing by the Customer to the Company have been paid and discharged
                 in full, the Company shall retain all legal and beneficial title in all Goods.
               </li>
               <li>If Goods to which legal title has not passed to the Customer are in the possession of the Customer,
                 the Customer is under an obligation to:
                 <ul>
                   <li>retain them in a good and merchantable condition until they are either paid for or collected by
                     the Company; and
                   </li>
                   <li>allow the Company, its servants or agents onto the premises where they are stored for the purpose
                     of inspecting and collecting the Goods.
                   </li>
                 </ul>
               </li>
               <li>In relation to Goods for which payment in full has not been received:
                 <ul>
                   <li>The relationship between the Customer and the Company shall be fiduciary;</li>
                   <li>The Customer will hold those Goods as bailee for the Company;</li>
                   <li>Where the Customer sells those Goods, the Customer does so as fiduciary agent of the Company;
                   </li>
                   <li>Where the Goods are disposed of, the monies resulting from the disposal and all other proceeds
                     received in respect of the Goods, including insurance proceeds will be kept separately in trust for
                     the Company;
                   </li>
                   <li>Where the Goods are disposed of, the Customer may only dispose of the Goods in the ordinary
                     course of its business on commercially reasonable terms;
                   </li>
                   <li>The Customer undertakes that until it delivers the Goods to a third party, it will store the
                     Goods on its premises separately from its own Goods, or those of any other person, and in a manner
                     which makes the Goods readily identifiable as the Company’s Goods.
                   </li>
                 </ul>
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="4">
           <li><strong>Access</strong>
             <ul>
               <li>The Customer irrevocably permits the Company or any person authorised by the Company in writing, upon
                 giving reasonable notice to enter the Customer’s premises or at premises where the Goods are reasonably
                 believed by the Company to be held on the Customer’s behalf for the purpose of examining or recovering
                 the Goods. The Customer also agrees to indemnify and hold the Company harmless for reasonable costs of
                 removal, enforcement, and legal action in respect of the removal of any Goods, the subject of this
                 Agreement.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="5">
           <li><strong>Transactions contemplated by this Agreement</strong>
             <ul>
               <li>The Company’s tender of delivery of Goods and Services under this Agreement is a condition of the
                 Customer’s duty to accept the Goods or Services provided and, unless otherwise agreed, the Customer’s
                 duty to pay for them.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="6">
           <li><strong>Disputes</strong>
             <ul>
               <li>If the Customer disputes any Goods sold or Services supplied by the Company are faulty, defective or
                 disputes the Invoices the Company has issued, the Customer must notify their reasons in writing to the
                 Company within 14 days of the Invoice date, failing which the Customer loses any right to dispute the
                 quality of the Goods, Services or value.
               </li>
             </ul>
           </li>
         </ol>
         <p><strong>&nbsp;</strong></p>
         <ol start="7">
           <li><strong>Whole Agreement</strong>
             <ul>
               <li>These terms and conditions together with the Application embody the whole agreement between the
                 parties and all previous dealings, representations and arrangements are hereby excluded and cancelled.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="8">
           <li><strong>Payment</strong>
             <ul>
               <li>The Customer agrees to pay all amounts due in clear funds from the date of invoice for the Goods
                 and/or Services provided by the Company.
               </li>
               <li>The Customer agrees that if it fails to pay in accordance with this clause, the Company may:
                 <ul>
                   <li>Charge a late payment fee of $50.00 on all amounts paid after the due date;</li>
                   <li>Charge interest on debts at 15% per annum from time to time;</li>
                   <li>Charge a dishonour handling fee in the amount of $25.00;</li>
                   <li>Withhold supply;</li>
                   <li>Sue for the money owing on the Goods or Services provided;</li>
                   <li>Take steps to secure monies owing and enforce such security;</li>
                   <li>Recover all collection and legal costs and expenses incurred in collecting overdue accounts
                     and/or enforcing security interests on an indemnity basis.
                   </li>
                 </ul>
               </li>
               <li>Failure to comply with clause 8.1 will constitute a breach of contract and the Company may treat the
                 whole Agreement as repudiated and act accordingly.
               </li>
               <li>In the event this agreement has been entered into by more than one party each party shall be jointly
                 and severely liable for any amount due.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="9">
           <li><strong>Default</strong>
             <ul>
               <li>If the Customer:
                 <ul>
                   <li>Fails to pay for any Goods or Services on the due date; or</li>
                   <li>Otherwise breached this agreement and failed to rectify such breach within seven day’s notice;
                     or
                   </li>
                   <li>Cancels delivery of Goods or Services; or</li>
                   <li>Commits an act of bankruptcy or allows a trustee in bankruptcy or receiver and manager to be
                     appointed to the Customer or any of its property; or
                   </li>
                   <li>Allow a judgment or order to be enforced or become enforceable against the Customer’s property.
                   </li>
                 </ul>
               </li>
             </ul>
           </li>
         </ol>
         <p>then the Company may enter upon the Customer’s premises (doing all that is necessary to gain access) where
           Goods supplied under this contract are situated at any time and re-take possession of any or all of the Goods
           the Company has supplied to the Customer and:</p>
         <ul>
           <li>Resell the Goods concerned;</li>
           <li>Terminate the agreement; and</li>
           <li>Sue for any monies owing.</li>
         </ul>
         <ul>
           <li>The Customer will be in default if the Customer does not pay any monies payable when called upon so to do
             and the Customer acknowledges and agrees that the Company is authorised to contact a credit reporting
             agency throughout the term of the Agreement to obtain a report about the creditworthiness of the Customer.
           </li>
           <li>The Customer authorises the Company to engage in the exchange of information with a credit reporting
             agency or with other such parties as are necessary to give effect to the contract and to the ongoing
             relationship between the parties hereto.
           </li>
           <li>The Company reserves the right to report a Customer’s delinquent account to a credit reporting agency
             should payment remain outstanding for more than 30 days.
           </li>
           <li>The Company may refer any outstanding account for debt collection or issue legal proceedings to recover
             any outstanding invoices. Should an account be referred for debt collection the Customer acknowledges and
             agrees to pay debt collection charges to be calculated at not less than 20% plus GST and will be incurred
             on the day the Company refers the matter to their nominated debt collection agency. The Customer shall also
             be liable for interest and all legal recovery costs associated with such action on a solicitor and own
             Customer or indemnity cost basis.
           </li>
         </ul>
         <p>&nbsp;</p>
         <ol start="10">
           <li><strong>Security Interest</strong>
             <ul>
               <li>The Customer consents to registration of the security interests arising in favour of the Company as a
                 result of these conditions and lodgment of a financing statement in respect of the security interests
                 with the Personal Property Securities Register, and must do everything (including obtaining consents,
                 signing and producing documents, completing documents or procuring that they are completed, and
                 supplying information) which the Company considers necessary or desirable for the purposes of the PPSA
                 including, without limitation:
                 <ul>
                   <li>ensuring that the security interests are registered, enforceable, continuously perfected and
                     otherwise effective in accordance with the PPSA;
                   </li>
                   <li>enabling the Company to apply for any registration, or give any notification, in connection with
                     the security interests; and
                   </li>
                   <li>enabling the Company to exercise rights in connection with the security interests in accordance
                     with the PPSA.
                   </li>
                 </ul>
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="11">
           <li><strong>Existing Agreements and security interests – Transitional and Non-transitional PPSA security
             interests</strong>
             <ul>
               <li>Where the Customer has previously entered into an agreement with the Company for the supply of Goods
                 on credit (Existing Agreement):
                 <ul>
                   <li>Upon any order being made by the Customer for the supply of Goods after the first provision of
                     this document to the Customer.
                   </li>
                   <li>these Terms shall apply to such supply and any subsequent supply of Goods.</li>
                   <li>any security interest (including any ROT security interest) granted by the Customer under an
                     Existing Agreement shall immediately vest in the Company.
                   </li>
                 </ul>
               </li>
               <li>Any order made by the Customer shall not constitute a new security agreement as between the parties,
                 but only further performance of these Terms unless expressly excluded in writing signed by the Company.
               </li>
               <li>This Agreement will not act as a merger of their rights but that the terms contained under such
                 Existing Agreement will be amended by the terms contained herein to the extent of any inconsistency.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="12">
           <li><strong>Creation of Charge</strong>
             <ul>
               <li>As further and alternative security for the obligations owing to the Company, the Customer hereby
                 grants a charge in favour of the Company over all of its rights title and interest in all of its
                 present and after acquired property, including real property owned by the Customer beneficially or
                 otherwise from time to time, and consents to a caveat or other registrable interest being lodged with
                 the relevant government department in respect of such charge. For the purposes of section 20(1) and (2)
                 PPSA, the Customer states that the charge is in all of the Customer’s present and after acquired
                 property (whether owned beneficially or otherwise) from time to time.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="13">
           <li><strong>Contracting out</strong>
             <ul>
               <li>Where these Terms are inconsistent with the PPSA, the <em>Australian Competition and Consumers Act
                 2009</em> (Cth) or any other legislation, to the extent permitted by law the parties are deemed to have
                 expressly excluded and replaced those statutory provisions.
               </li>
               <li>The Company and the Customer contract out of the provisions of sections 95, 96, 117, 118, 121(4),
                 130, 132(3)(d), 132(4), 142 and 143 of the PPSA and nothing in those provisions will apply to this
                 Agreement.
               </li>
               <li>The Company and the Customer acknowledge the Customer is a grantor and the Company is or will become
                 the holder of a Purchase Money Security Interest (PMSI) by virtue of this Agreement and/or the PPSA.
               </li>
               <li>The Customer hereby consents to and appoints the Company to be an interested person and their
                 authorized representative for the purposes of section 275(9) PPSA.
               </li>
               <li>The benefit of this contract and the security interest/s created hereunder may be assigned by the
                 Company, but not by the Customer.
               </li>
             </ul>
           </li>
         </ol>
         <p><strong>&nbsp;</strong></p>
         <ol start="14">
           <li><strong>Appointment</strong>
             <ul>
               <li>The Customer appoints and authorizes the Company any director, officer or solicitor of the Company
                 time to time as its attorney to execute and deliver in its name:
                 <ul>
                   <li>such form of security interest, charge or mortgage as the Company may reasonably require to
                     better secure any charge granted under these conditions; and
                   </li>
                   <li>any financing statement, consent form, security document or other document whatsoever for the
                     purpose of:
                   </li>
                   <li>registering any caveat or other registrable interest in respect of the charge or any security
                     interest whatsoever granted under these conditions; or
                   </li>
                   <li>otherwise perfecting and enforcing the security interest.</li>
                 </ul>
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="15">
           <li><strong>Right to vary terms and conditions</strong>
             <ul>
               <li>These terms and conditions shall govern all transactions between the parties.</li>
               <li>The Customer and the Company agree that the terms of this Contract may be altered unilaterally by the
                 Company giving 14 days’ written notice to the Customer. Clerical errors are subject to correction
                 without notification.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="16">
           <li><strong>Set-off</strong>
             <ul>
               <li>The Customer agrees that:
                 <ul>
                   <li>The Company may set-off any credit amount that the Company owes to the Customer against any debt
                     due by the Customer to the Company at the Company’s sole discretion and without notice;
                   </li>
                   <li>The Customer is not entitled to withhold payment of any money in respect of any alleged set-off
                     or claim the Customer might have against the Company.
                   </li>
                 </ul>
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="17">
           <li><strong>Limitation of Liability</strong>
             <ul>
               <li>Except to the extent required by law, all Goods are purchased by the Customer “as is” and the Company
                 will not be responsible for any damage or defect to the Goods.
               </li>
               <li>Subject to any conditions and warranties mandatorily implied by law and to any conditions and
                 warranties expressly contained herein, all conditions, warranties and representations on the part of
                 the Company, whether express or implied, are hereby expressly excluded.
               </li>
               <li>Subject to any legislation or express agreement to the contrary, the Customer acknowledges that it
                 does not rely on the skill and judgment of the Company, its employees, agents or contractors in
                 determining whether the Goods to be supplied are fit for any particular purpose and the Customer
                 further acknowledges that any advice with respect to the use of Goods is given on the basis that the
                 Company assumes no obligation or liability for advice given or results obtained, all such advice being
                 given and accepted by the Customer is at the Customer’s risk.
               </li>
               <li>To the extent permitted by law, the Customer has responsibility for ensuring that the Goods are not
                 used for any purpose for which they are not suitable and warrants and represents that it has had
                 adequate opportunity to inspect the Goods and obtain independent expert advice.
               </li>
               <li>To the extent that any warranty as to purpose or merchantable quality is implied by law and the
                 Company is liable, the parties agree that the sole and exclusive remedy of the Customer will be to
                 return the Goods and obtain a refund or at the Company’s election for the Company to repair or replace
                 the Goods as soon as reasonably practical.
               </li>
               <li>To the extent permitted by law, in relation to services, the Company’s liability is limited to
                 supplying the services again or payment of the cost of having the services supplied again.
               </li>
               <li>To the extent permitted by law, under no circumstances shall the Company be liable for any direct or
                 indirect loss (including without limitation loss of profit) or damage whether special or consequential
                 however arising (including as a result of negligence) except for any loss or damage (excluding loss of
                 profit and special or consequential damage) suffered by the Customer as a direct result of a failure by
                 the Company to perform their contractual obligations.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="18">
           <li><strong>Severance and Waiver</strong>
             <ul>
               <li>If any provision of these Terms will be invalid, void, illegal or unenforceable the validity,
                 existence, legality and enforceability of the remaining provisions will not be affected, prejudiced or
                 impaired.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="19">
           <li><strong>Jurisdiction and Notices</strong>
             <ul>
               <li>The agreement shall be deemed to have been made in the Governing State and shall be interpreted in
                 accordance with the laws of the Governing State, Australia, and the parties submit to the non-exclusive
                 jurisdiction of that State’s Courts, except to the extent that the laws of the Commonwealth of
                 Australia apply.
               </li>
               <li>That in addition to any other means permitted by law, any documents, notifications or court
                 proceedings may be given or served upon the Customer, its successors and permitted assignees in the
                 same manner as any notice or document may be given under Part 8.5 of the PPSA and will be deemed so
                 given or served.
               </li>
             </ul>
           </li>
         </ol>
         <p>&nbsp;</p>
         <ol start="20">
           <li><strong>Privacy</strong>
             <ul>
               <li>I/We acknowledge, accept, consent and warrant that I/ we have obtained the consent of any person
                 whose personal information appears herein (if any) to the use of information in accordance with the
                 provisions of these terms.
               </li>
               <li>The Company may use personal information contained in the application to obtain credit reports
                 containing consumer credit information and/or commercial credit information about the Applicant from
                 credit reporting agencies.
               </li>
               <li>The Company may use such personal information and any information disclosed by credit reporting
                 agencies, in accordance with the <em>Privacy Act 1988</em> (Cth), in assessing or enforcing the credit
                 application including with respect to delinquent accounts and in accordance with the Company’s privacy
                 policy, as amended. Personal information may be disclosed to or between the Company’s related
                 companies, contractors, other credit providers, finance providers, service providers and the Company’s
                 risk insurers and debt collectors. The Company hereby gives notice in accordance with Section 18E(8)(c)
                 of the <em>Privacy Act</em> that any personal information which is permitted to be kept on a credit
                 information file might be disclosed to credit reporting agencies now or in the future. Upon request,
                 any person may gain access to his/her personal information.
               </li>
             </ul>
           </li>
         </ol>
       </div>
      </div>

      {/* START Service List Section */}
      <div
        className={
          "w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4  xl:grid-flow-row-dense p-4 md:p-8 text-gray-500 font-bold text-lg  bg-gray-200 bg-cover "
        }
      >
        {services.map((service) => {
          return (
            <div key={service.Id} className="my-1">
              <CheckMark Color={" text-j2blue"} />
              <Link
                to={`/our-services/${service.Name.toLowerCase()}`}
                className={"hover:text-j2green hover:underline ml-2"}
              >
                {service.ServiceName.toUpperCase()}
              </Link>
            </div>
          );
        })}
      </div>
      {/* END Service List Section */}

      {/* START Hero Info Section */}
      <div
        className={
          "bg-black bg-cover bg-center  flex flex-col " +
          "flex-wrap mt-auto md:py-24 md:pl-24  2xl:py-48  right-0 left-0 mx-0 py-8 w-full md:h-full items-center md:items-start"
        }
      >
        <div
          className={
            "pl-4 xl:pl-10 text-center align-middle  flex flex-col items-center justify-center"
          }
        >
          <div className={"w-full text-left"}>
            <h1 className={"text-3xl  md:text-6xl font-bold text-white"}>
              <span className={"text-j2green"}>&#123;</span> Your Computer
              Doesn't Boot?
            </h1>
          </div>
          <div className={"w-full mt-6 text-left text-white text-lg"}>
            Simply make a booking for one of our technicians to visit you, and
            it will be good as new in no time!
          </div>
          <div className={"w-full mt-8 text-left"}>
            <BookATechnicianButton />
          </div>
        </div>
      </div>
      {/* END Hero Info Section */}
    </div>
  );
};

export default Contact;
