import CheckMark from "./CheckMark";
import BookATechnicianButton from "./BookATechnicianButton";
import React, { useState, useEffect } from 'react';

export const Pricing = ({percentageReduction = 0}) => {
  const standardCost = parseFloat(process.env.REACT_APP_PER_HALF_HR_COST);
  const [cost, setCost] = useState(standardCost);
  const [originalCost, setOriginalCost] = useState(standardCost);
  const [isDiscounted, setIsDiscounted] = useState(false);

  useEffect(() => {
    console.log(percentageReduction);
    if (percentageReduction != null && percentageReduction > 0) {
      const reduction = standardCost * (percentageReduction / 100);
      setIsDiscounted(true);
      setCost(reduction);
    }
  }, [percentageReduction, standardCost]);

  return (
    <div
      className={
        "bg-white flex flex-col  justify-center item my-6 sm:my-12 w-11/12 sm:w-full ml-4" +
        " xs:mx-auto "
      }
    >
      <div
        className={
          "flex flex-col flex-wrap mb-8  md:flex-row  mx-auto justify-between"
        }
      >
        <div
          className={"flex-initial w-4/5 sm:w-96 my-6 sm:px-8 justify-center"}
        >
          <h1
            className={
              "text-4xl md:text-6xl font-bold text-j2blue text-left justify-center"
            }
          >
            <span className={"text-j2green"}>&#123;</span> Simple Pricing
          </h1>
          <div className={"flex flex-row flex-wrap my-6 md:mt-8"}>
            Is a computer error stopping you from completing an urgent work or
            uni assignment?
            <br />
            Are you fed up of the slow speed of your PC?
            <br />
            Our expert team can help.
          </div>

          {isDiscounted &&
              <div className={"font-bold"}>Note: this special price applies only to this service.</div>
          }
        </div>

        <div className={"flex flex-col flex-wrap"}>
          <div className="flex flex-col flex-wrap justify-center">
            <div className="shadow-lg bg-white">
              <div className={"bg-j2green   p-6 text-center"}>
                {isDiscounted &&
                <span className={
                      "text-2xl flex flex-col flex-wrap mt-2 text-white font-bold line-through"
                    }
                    >
                    Normally ${originalCost}
                    </span>
                }
                <span
                  className={
                    "text-6xl flex flex-col flex-wrap mt-2 text-white font-bold"
                  }
                >
                  ${cost}
                </span>
                <h1>Per Half Hour</h1>
              </div>
              <div className="p-6">
                <p className="text-gray-700 flex flex-col flex-wrap text-base mb-4">
                  <div>
                    <CheckMark /> Expert technician at your home or office
                  </div>
                  <div className={"mt-2"}>
                    <CheckMark /> Solve your computer problems
                  </div>
                  <div className={"mt-2"}>
                    <CheckMark /> Nights and Weekends
                  </div>
                </p>
                <BookATechnicianButton
                  InvertColor={true}
                  Styles={"md:w-full"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
