import { services } from "../../data/services";
import { useParams } from "react-router-dom";
import BookATechnicianButton from "../../components/BookATechnicianButton";
import { useEffect } from "react";
import NotFoundPage from "../Errors/NotFoundPage";
import { HowToBook } from "../../components/HowToBook";
import { Pricing } from "../../components/Pricing";
import { HeroSection } from "../../components/HeroSection";
import SortedServiceAreaList from "../../helper/ServicesAreaHelper";
import useDocumentTitle from "../../hook/useDocumentTitle";
import {branch} from "../../data/config";

const Service = () => {
  let params = useParams();
  const service = services.find((service) => service.Name.toLowerCase() === params.Name.toLowerCase());

  if (!service)
  {
    window.location.href = '/not-found'
  }

  const locationList = SortedServiceAreaList.map(obj => obj.name);
  locationList[locationList.length - 2] = `${locationList[locationList.length - 1]} & ${locationList[locationList.length - 1]}`;
  locationList.pop();
  const commaSeperatedLocationList = locationList.join(", ");

  useDocumentTitle(`${service.ServiceName} | ${branch[0].city} Mobile Computer Help`);

  if(!service.QNA.some(q => q.Q === "Do you come to me?")){
    service.QNA.push(
        {
          Q: `Do you come to me?`,
          A: `Yes! We can come to you in ${commaSeperatedLocationList}.`,
        }
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.Name]);

  return service ? (
    <div
      className={
        "flex flex-col flex-wrap mx-0 my-0 py-0 px-0 w-screen"
      }
    >
      {/* START Banner Section */}
      <div
        className={
          "flex flex-col left-0 h-auto py-12 pl-20 pr-6 xl:px-48 xl:h-96 bg-slider-1 bg-cover w-full text-white  xl:pt-32 overflow-hidden"
        }
      >
        <div className={"mx-auto mx:pt-12 mx:pt-16 mx:pl-48 w-full"}>
          <div className={"text-3xl  xl:text-6xl font-extrabold"}>
            {service.ServiceName}
          </div>
          <div className={"sm:flex flex-row text-sm pt-8"}>
            <span>
              <a
                href={"/"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Home
              </a>
            </span>
            <span className={"mx-2 text-xl"}>/</span>

            <span>
              <a
                href={"/our-services"}
                className={"hover:underline hover:text-gray-700 text-xl"}
              >
                Services
              </a>
            </span>
            <span className={"mx-2 text-xl"}>/</span>
            <span className={"text-xl"}> {service.ServiceName}</span>
          </div>
        </div>
      </div>
      {/* END Banner Section */}

      {/* START Introduction Section */}
      <div
        className={
          "flex flex-col md:flex-row  mx-auto my-0 w-full px-8  py-8 xl:px-36 xl:pt-20 2xl:w-4/5  items-start justify-start  content-center "
        }
      >
        <div
          className={
            "order-2 xl:order-1 xl:p-4  py-10 2xl:pt-0 2xl:py-2 w-full xl:w-1/2 self-end "
          }
        >
          <img
            src={service.ImgURL}
            alt={service.ServiceName}
            className={"flex flex-row self-center justify-center items-center"}
          />
        </div>

        <div
          className={
            "order-1 xl:order-2 flex flex-col xl:w-1/2 text-4xl  text-j2blue justify-center"
          }
        >
          <div className={"w-full"}>
            <div>
              <span className={"text-j2green font-extrabold mr-2"}>
                &#123;
              </span>
              <span className={"text-bold font-extrabold"}>
                {service.Subtitle}
              </span>
            </div>

            <div
              className={
                "mt-8 text-gray-600 text-base xl:text-xl  whitespace-pre-wrap pr-10 font-bold"
              }
            >
              {service.Description}
            </div>

            <div className={"mt-4 mb-4"}>
              <BookATechnicianButton InvertColor={true} />
            </div>
          </div>
        </div>
      </div>
      {/* END Introduction Section */}

      {/* START Q&A Section */}
      <div
        className={
          "flex flex-col flex-wrap mx-auto my-0 w-full p-8 xl:px-36 xl:pt-16 items-start content-center w-full 2xl:w-4/5 whitespace-pre-wrap}"
        }
      >
        {service.QNA.map((item) => {
          return (
            <div key={`${item.Q}`} className={"mb-8"}>
              <div className={"w-full text-left mb-6"}>
                <h1
                  className={
                    "text-4xl whitespace-pre-wrap font-bold text-j2blue"
                  }
                >
                  <span className={"text-j2green"}>&#123;</span>
                  {item.Q}
                </h1>
              </div>
              <div
                className={
                  "w-full whitespace-pre-wrap mt-3 font-bold text-left text-gray-500 text-base"
                }
              >
                {item.A}
              </div>
            </div>
          );
        })}
      </div>
      {/* END Q&A Section */}

      {/* START Pricing Section */}
      <Pricing percentageReduction={service.PercentReduction} />
      {/* END Pricing Section */}

      {/* BEGIN How To Book Section */}
      <HowToBook />
      {/* END How To Book Section */}

      {/* START Hero Info Section */}
      <HeroSection />
      {/* END Hero Info Section */}
    </div>
  ) : (
    <NotFoundPage />
  );
};

export default Service;
